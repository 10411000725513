import React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'

import Main from './container/Main'
import SignIn from './container/SignIn'
import SignUp from './container/SignUp'


import DashboardRecruiter from './container/recruiter/DashboardRecruiter'
import PositionList from './container/recruiter/PositionListContainer'
import ApplicationList from './container/recruiter/ApplicationListContainer'
import SuggestionList from './container/recruiter/SuggestionListContainer'

import DashboardCandidate from './container/candidate/DashboardCandidate'
import UploadPage from './container/candidate/UploadContainer'
import ClipBox from './container/candidate/ClipBoxContainer'
import JobBoard from './container/candidate/JobBoardContainer'
import ProfilePage from './container/candidate/ProfileContainer'
import ConnectPage from './container/candidate/ConnectPageContainer'

// import Dashboard from './components/Dashboard'
// import Wizard from './components/Wizard'
// import Cards from './components/Cards'

// import Signup from './components/Signup'
import ScrollToTop from './component/ScrollTop'

export default props => (
    <HashRouter>
      <ScrollToTop>
        <Switch>
            {/* Main */}
            <Route exact path='/' component={ Main } />

            {/* Recruiter */}
            <Route exact path='/dashboardrecruiter' component={ DashboardRecruiter } />
            <Route exact path='/positionlist' component={ PositionList } />
            <Route exact path='/applicationlist' component={ ApplicationList } />
            <Route exact path='/suggestionlist' component={ SuggestionList } />

            {/* Candidate */}
            <Route exact path='/dashboardcandidate' component={ DashboardCandidate } />
            <Route exact path='/uploadpage' component={ UploadPage } />
            <Route exact path='/cliplist' component={ ClipBox } />
            <Route exact path='/jobboard' component={ JobBoard } />
            <Route exact path='/profilepage' component={ ProfilePage } />
            <Route exact path='/connectpage' component={ ConnectPage } />

            {/* Shared */}
            <Route exact path='/signin' component={ SignIn } />
            <Route exact path='/signup' component={ SignUp} />
            
          {/* <Route exact path='/signup' component={ Signup } />
          <Route exact path='/wizard' component={ Wizard } />
          <Route exact path='/cards' component={ Cards } /> */}
        </Switch>
      </ScrollToTop>
    </HashRouter>
  )