import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import { IconButton,Tooltip } from '@material-ui/core/';

import ViewIcon from '@material-ui/icons/Pageview';
import NoteIcon from '@material-ui/icons/Note';

import ThumbIcon from '@material-ui/icons/ThumbUp';
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import MoreMenu from '../../MoreMenu'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        // maxWidth: 700,
        // color: theme.palette.text.secondary,
    },
    padding:{ 
        padding: theme.spacing(2)
    },
    padding1:{ 
        padding: theme.spacing(1)
    },
    card: {
         padding: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(2),
      },
}));

export default function CandidateCard(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }
  
    function handleClose() {
      setAnchorEl(null);
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function handleOption(option) {
        //option the is one user choose
        // setOpen(true);     
        console.log(option)  
    }

    let options = null
        if (props.options) {
            options = (
                    <MoreMenu options={props.options} clicked={handleOption}/>
            );         
    }

    return (
    <React.Fragment>
        <Paper className={classes.paper}>
            <Grid container direction="row" >
                    <Grid className={classes.padding} item xs={4}>
                        <Typography variant="h6">
                            {props.name}
                        </Typography>
                        
                    </Grid>
                    <Grid className={classes.padding} item container xs={4}>
                        <Grid item xs>
                            <ThumbIcon className={classes.extendedIcon}/>
                        </Grid>
                        <Grid item xs>
                        <Typography variant="body1">
                            4.5/5
                        </Typography>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="View Notes">
                        <IconButton onClick={handleClick}>
                            <NoteIcon />
                        </IconButton>
                        </Tooltip>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <Typography className={classes.typography}>{props.note}</Typography>
                        </Popover>
                    </Grid>
                    
                <Grid className={classes.padding1} item xs>
                    <Fab aria-label="checked" size="small" variant="extended" 
                        style={{backgroundColor: "lightgreen"}}
                        onClick={() => props.clicked(props.name)}
                        >
                        <ViewIcon className={classes.extendedIcon} />
                            View
                    </Fab>
                    
                </Grid>
            {options}
                
            </Grid>
        </Paper>

    </React.Fragment>
  );
}