import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';

import classes from './ClipBoxContainer.module.css'
import DialogClip from '../../component/DialogClip'

import ClipGrid from '../../component/candidate/ClipGrid'
import NavBar from './NavBarCandidate';
import SideButton from '../../component/SideButton'
import Header from '../../component/candidate/Profile/HeaderSection'
 import { Divider } from '@material-ui/core';

const options = ['Public Profile','Refer to a friend','Placeholder']
const clipList = [
    {
        title: 'EP Clip',
        category: 'Elevator Speech',
        sub1: false,
        subCategory: '',
        link: 'https://www.youtube.com/watch?v=HWW-jA6YjHk',
        tags: ['Tag 1','Tag 2','Tag 3'],
    },
    {
        title: 'Project Clip',
        category: 'Project',
        sub1: true,
        subCategory: 'Sub 1',
        link: 'https://www.youtube.com/watch?v=HWW-jA6YjHk',
        tags: ['Tag 4','Tag 5','Tag 6'],
    },
    {
        title: 'Failure Clip',
        category: 'Failure',
        sub1: false,
        subCategory: '',
        link: 'https://www.youtube.com/watch?v=HWW-jA6YjHk',
        tags: ['Tag 1'],
    },
    {
        title: 'Teamwork Clip',
        category: 'Teamwork',
        sub1: true,
        subCategory: 'Teamtime',
        link: 'https://www.youtube.com/watch?v=HWW-jA6YjHk',
        tags: ['Tag 2','Tag 6'],
    },
    {
        title: 'Leadership Clip',
        category: 'Leadership',
        sub1: false,
        subCategory: '',
        link: 'https://www.youtube.com/watch?v=HWW-jA6YjHk',
        tags: ['Tag 3','Tag 7'],
    },

];
// const backgroundShape = require('../images/shape.svg');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 200
      },
    });

class ClipBoxContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            dialog : false,
            userProfile : {
                name: 'Lewis Nguyen',
                title: 'Undergraduate Student at University of Nebraska Lincoln',
                location: 'Lincoln, Nebraska',
                rating: 4,
            },
            title: ''
        }
        this.handleDialog = this.handleDialog.bind(this)

        this.handleSideOption = this.handleSideOption.bind(this)
        this.handleExpandClip = this.handleExpandClip.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleEmbeddedLink = this.handleEmbeddedLink.bind(this)
    }
    handleDialog = (state) => {
        this.setState({dialog:state})
    }

    handleSideOption (option) {
        console.log(option)
    }


    handleExpandClip (title) {
        this.setState({dialog:true})
        this.setState({title:title})
    }
    handleClose () {
        this.setState({dialog:false})
    }

    handleEmbeddedLink (link) {
        let embeddedLink = ''
        let videoID = ''
        // eslint-disable-next-line
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = link.match(regExp);
        if (match && match[2].length === 11) {
            videoID = match[2];
        } else {
            return 'error';
        }

        embeddedLink = '//www.youtube.com/embed/'+ videoID
        return embeddedLink
    }

    render() {
    const currentPath = this.props.location.pathname

    var dialogs = null
    // if (this.state.option === 'Clip'){
    const title = this.state.title
    if (this.state.dialog){
        
        var clip = clipList.filter(function(clip) {
            return clip.title === title;
        });
        dialogs = (
            <React.Fragment>
                <DialogClip
                    type={'public'}
                    open={this.state.dialog}
                    closed={this.handleClose}
                    title={clip[0].title}
                    src={this.handleEmbeddedLink(clip[0].link)}
                />
            </React.Fragment>
        );    
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <NavBar currentPath={currentPath}/>
            <div className={classes.root}>
                <Typography variant="h4" align="center" gutterBottom>Customize Public Profile </Typography>
                

                <div className={classes.wrapper}>
                    <div className={classes.middle}>
                    <Header profile={this.state.userProfile} />
                    <Divider variant="middle" />
                        <ClipGrid 
                            type={'public'}
                            title={'Published Clip'}
                            expandClip={this.handleExpandClip}
                            clips={clipList} />
                        <br/>
                    </div>
                    <div className={classes.side}>
                        <SideButton options={options} clicked={this.handleSideOption}/>
                        {/* <SideOptions clicked={this.handleDialog} /> */}
                    </div>
                </div>
            </div>
            {dialogs}
        </React.Fragment>
      )
    }
}
export default withRouter(withStyles(styles)(ClipBoxContainer));