import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { ListItem, ListItemAvatar, ListItemText, ListItemIcon, Avatar} from '@material-ui/core/'

import PostIcon  from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(2, 1),
    maxWidth: 700,
    minWidth: 700,
    marginBottom: 20,
    cursor: 'pointer',
  },
}));

export default function NewPost(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} onClick={props.newPost}>
          <ListItem style={{cursor: 'inherit'}}>
              
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />         
            </ListItemAvatar>
            <ListItemIcon style={{maxWidth: '10px',cursor: 'inherit'}}>
                <PostIcon  />
              </ListItemIcon>
            <ListItemText primary="New Post ?" />
          </ListItem>
    </Paper>
  );
}