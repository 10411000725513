import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        maxWidth: '120px', 
        maxHeight: '40px', 
        minWidth: '120px', 
        minHeight: '40px'
    },
    highlightButton: {
        margin: theme.spacing(1),
        maxWidth: '120px', 
        maxHeight: '40px', 
        minWidth: '120px', 
        minHeight: '40px',
        backgroundColor: 'lightpink',
        fontWeight: 'bold'
    }
  }));
  
  export default function SideButton(props) {
    const classes = useStyles();


    // props.position is a current tab
    // props.change is switching to different tab will change the props.position

    return (
      <div style={{position: 'fixed'}}>
        <Grid container direction='column'>
            <Grid>
                { props.position === 1 ? <Button variant="outlined" color="primary" className={classes.highlightButton}>Pending</Button> 
                : <Button variant="outlined" color="primary" className={classes.button} onClick={() => props.change(1)}>Pending</Button>}
            </Grid>
            <Grid>
                { props.position === 2 ? <Button variant="outlined" color="primary" className={classes.highlightButton}>Connected</Button> 
                : <Button variant="outlined" color="primary" className={classes.button} onClick={() => props.change(2)}>Connected</Button>}
            </Grid>
            <Grid>
                { props.position === 3 ? <Button variant="outlined" color="primary" className={classes.highlightButton}>Suggestion</Button> 
                : <Button variant="outlined" color="primary" className={classes.button} onClick={() => props.change(3)}>Suggestion</Button>}
            </Grid>
        </Grid>   
      </div>
    );
  }