import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Grid,Typography,Input} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
    
      margin: theme.spacing(1),
      minWidth: 200,
      maxWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export default function HeaderWithSort(props) {
    const classes = useStyles();
    return (
        <form className={classes.root} autoComplete="off">
            <Grid container direction='row'>
                <Grid item xs container justify="flex-start">
                <FormControl className={classes.formControl}>
                <InputLabel htmlFor="adornment-password">Search</InputLabel>
                    <Input
                    id="adornment-password"
                    value={props.search}
                    onChange={props.handleSearch}
                    endAdornment={
                        <InputAdornment position="end">
                            
                            {props.search !== '' 
                            ?   <IconButton onClick={props.clearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            :   <IconButton disabled>
                                    <SearchIcon />
                                </IconButton>}
                        </InputAdornment>
                    }
                    />
                </FormControl>
                </Grid>
                <Grid item xs>
                    <Typography style={{marginTop: '15px'}} variant="h5" align="center" gutterBottom>{props.title}</Typography>
                </Grid>
                <Grid item xs container justify="flex-end">
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel>
                    Sort by
                    </InputLabel>
                    <Select
                    value={props.value}
                    onChange={props.handleSort}
                    >
                        {props.options.map(each => (
                            <MenuItem key={each} value={each}>{each}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
        </form>
    )
}