import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import CandidateCard from './CandidateCard'

const options = [
    'Change Tier',
    'Change Note',
    'Reject',
]

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PositionTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <React.Fragment>
        <div className={classes.root}>
                <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                >
                <Tab label="Tier 1" {...a11yProps(0)} />
                <Tab label="Tier 2" {...a11yProps(1)} />
                <Tab label="Tier 3" {...a11yProps(2)} />
                <Tab label="Interested" {...a11yProps(3)} />
                </Tabs>
            
            <TabPanel value={value} index={0}>
                {props.tier1.map(each => (
                    <CandidateCard 
                        key={each.name}
                        name={each.name}
                        note={each.note}
                        options={options}
                        clicked={(name) => props.clickView(name)}
                        />
                    ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {props.tier2.map(each => (
                    <CandidateCard 
                        key={each.name}
                        name={each.name}
                        note={each.note}
                        options={options}
                        clicked={(name) => props.clickView(name)}
                        />
                    ))}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {props.tier3.map(each => (
                    <CandidateCard 
                        key={each.name}
                        name={each.name}
                        note={each.note}
                        options={options}
                        clicked={(name) => props.clickView(name)}
                        />
                    ))}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {props.interested.map(each => (
                    <CandidateCard 
                        key={each.name}
                        name={each.name}
                        note={each.note}
                        options={options}
                        clicked={(name) => props.clickView(name)}
                        />
                    ))}
            </TabPanel>
        </div>
    
    </React.Fragment>
  );
}