import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';


import PreviewIcon from '@material-ui/icons/OndemandVideo';
import ViewIcon from '@material-ui/icons/ArrowForward';
import UnConnectIcon from '@material-ui/icons/LinkOff';

import FriendIcon from '@material-ui/icons/People';
import AssociateIcon from '@material-ui/icons/Work';
//import Typography from '@material-ui/core/Typography';

import Tooltip from '@material-ui/core/Tooltip';

import HeaderWithSort from '../../HeaderWithSort'

const options=['Type','Name']

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  video: {
    padding: theme.spacing(1),
  },
  rightSort: {
    position: 'absolute',
    right: '0px',
    width: '300px',
    padding: '10px',
  }
}));

export default function ConnectCard(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [sort,setSort] = React.useState('');
    const [search, setSearch] = React.useState('');
    const [newList, setNewList] = React.useState(props.list);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSort = (event) => {
        setSort(event.target.value);
        var result = []
        if(event.target.value === 'Type'){
            result = newList.sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0));
        }
        else if(event.target.value === 'Name'){
            result = newList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }
        else {
            result = props.list
        }
        setNewList(result)
    }
    const handleSearch = (event) => {
        
        setSearch(event.target.value);
        var result;
        if(event.target.value.length > 0){
            if(newList.length > 0){
                result = newList.filter(each => each.name.toLowerCase().includes(event.target.value) || each.position.toLowerCase().includes(event.target.value))
            }
            else {
                result = props.list.filter(each => each.name.toLowerCase().includes(event.target.value) || each.position.toLowerCase().includes(event.target.value))
            }
            
            setNewList(result)
        }
        else {
            setNewList(props.list)
        }
        

        
        
    }
    const handleClearSearch = () => {
        setSearch('');
        setNewList(props.list)
    }

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

  return (
    <List className={classes.root}>
        <HeaderWithSort search={search} handleSearch={handleSearch} clearSearch={handleClearSearch} value={sort} title='My Network' options={options} handleSort={handleSort} />
        {newList.map(each => (
            <React.Fragment key={each.userID}>
            <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />
              
            </ListItemAvatar>
            <ListItemIcon>
                {each.type === 'associate' 
                ?   <React.Fragment>
                        <Tooltip title="Associate">
                            <AssociateIcon /> 
                        </Tooltip>
                    </React.Fragment>

                :   <React.Fragment>
                        <Tooltip title="Friend">
                            <FriendIcon />
                        </Tooltip>
                    </React.Fragment>}
            </ListItemIcon>
            <ListItemText primary={each.name} secondary={each.position} />
            
            <ListItemSecondaryAction>
                <Tooltip title="Preview">
                    <IconButton onClick={handleClick} edge="end">
                        <PreviewIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <iframe 
                    title={each.title} 
                    src={each.src} 
                    height={300} width={500}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    className={classes.video}
                    />
                </Popover>

                <Tooltip title="View Public Profile">
                    <IconButton onClick={props.viewPublicProfile} edge="end">
                        <ViewIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Disconnect">
                    <IconButton onClick={() => props.disconnect(each.name,each.userID)} edge="end">
                        <UnConnectIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            </React.Fragment>
        ))}
    </List>
  );
}