import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';
import {Grid, Fab, Button} from '@material-ui/core'

import UploadIcon from '@material-ui/icons/CloudUpload';

import NavBar from './NavBarCandidate';
import UploadForm from '../../component/candidate/UploadPage/UploadForm'

// const backgroundShape = require('../images/shape.svg');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 200
      },
    });

class UploadContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            dialog : false,
            newClip : {
                title: '',
                category: '',
                sub1: false,
                subCategory: '',
                link: '',
                tags: [],
            }
        }
        this.handleDialog = this.handleDialog.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleDialog = (state) => {
        this.setState({dialog:state})
    }
    handleCreate (newClip) {
        this.setState({newClip:newClip})
        this.handleDialog(false)
    }
    handleSubmit () {
        console.log('success full upload to database')
        this.props.history.push('/dashboardcandidate')
    }

    handleEmbeddedLink (link) {
        let embeddedLink = ''
        let videoID = ''
        // eslint-disable-next-line
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = link.match(regExp);
        if (match && match[2].length === 11) {
            videoID = match[2];
        } else {
            return 'error';
        }

        embeddedLink = '//www.youtube.com/embed/'+ videoID
        return embeddedLink
    }

    render() {
        const currentPath = this.props.location.pathname

    
        let dialogs = null
        if (this.state.dialog) {
            dialogs = (
                <React.Fragment>
                    <UploadForm 
                    newClip={this.state.newClip}
                    open={this.state.dialog}
                    closed={() => this.handleDialog(false)}
                    created={this.handleCreate}
                    />
                </React.Fragment>
            );         
        }

        let main = null
        if(this.state.newClip.title === ''){
            main = (
                <React.Fragment>
                    <Grid
                        container justify='center'>
                        <Grid item>
                            <Fab variant="extended" color="primary" 
                                style={{backgroundColor: "lightblue", color: "black"}}
                                onClick={() => this.handleDialog(true)}>
                                <UploadIcon style={{marginRight: "10px"}} />
                                    Upload
                            </Fab>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );   
        }
        else {
            let embeddedLink = this.handleEmbeddedLink(this.state.newClip.link)
            main = (
                <React.Fragment>
                    <Grid container justify='center'>
                        <Grid item>
                            <Fab variant="extended" color="primary" 
                                style={{backgroundColor: "lightpink", color: "black"}}
                                onClick={() => this.handleDialog(true)}>
                                <UploadIcon style={{marginRight: "10px"}} />
                                    Edit
                            </Fab>
                        </Grid>
                    </Grid>

                    <Grid style={{margin: '10px'}} container justify='center'>
                        <Grid item>
                            <iframe 
                                title={this.state.newClip.title} 
                                src={embeddedLink} 
                                height={600} width={900}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                            />
                        </Grid>
                    </Grid>

                    <Grid container justify='center'>
                        <Grid item>
                            <Button
                                variant="contained"
                                style={{backgroundColor: "lightgreen", color: "black"}}
                                onClick={this.handleSubmit}>
                                    Submit
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ); 
        }

        return (
            <React.Fragment>
            <CssBaseline />
            <NavBar currentPath={currentPath}/>
                <Typography variant="h4" align="center" gutterBottom> Upload Page </Typography>

            {dialogs}
            {main}
            </React.Fragment>
        )
    }
}
export default withRouter(withStyles(styles)(UploadContainer));