import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ShareIcon from '@material-ui/icons/Share';
import CommentIcon from '@material-ui/icons/Comment';

import {ButtonGroup,Button,Collapse} from '@material-ui/core/'
 
import MoreMenu from '../../MoreMenu'
import CommentInput from './CommentInput'

const options = ['Share post','Unfollow','Report post']

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 700,
    minWidth: 700,
    marginBottom: 20,
  },
  cardContent:{
    paddingTop: 5,
    paddingBottom: 10,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Status(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [love, setLove] = React.useState(props.love);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleLove = () => {
    setLove(true);
    props.likeClicked(props.id,'love')
  };
  const handleUnLove = () => {
    setLove(false);
    props.likeClicked(props.id,'unlove')
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
            <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />
        }
        action={
          <MoreMenu options={options} clicked={(option) => props.moreClicked(props.id,option)} />
        }
        titleTypographyProps={{variant:'h6' }}
        title={props.name}
        subheader={props.position}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="body1">
          {props.statusText}
        </Typography>
      </CardContent>
      {props.img ? 
      <CardMedia
          className={classes.media}
          height="100"
          image={props.img}
          title="Status Image"
        /> : null }
      <CardActions disableSpacing>
        <ButtonGroup fullWidth style={{maxHeight:'40px'}}>
          {love 
          ? <Button
            style={{backgroundColor: 'pink'}}
            size="small"
            onClick={handleUnLove}
            >
              <FavoriteIcon style={{paddingRight: '10px'}}/> Love
            </Button>
          : <Button
            // style={{backgroundColor: 'pink'}}
            size="small"
            onClick={handleLove}
            >
              <FavoriteBorderIcon style={{paddingRight: '8px'}}/> Love
            </Button> 
          }
         <Button
            size="small"
            onClick={handleExpandClick}
          >
            <CommentIcon style={{paddingRight: '8px'}}/> Comment
          </Button>
          <Button
            size="small"
          >
            <ShareIcon style={{paddingRight: '8px'}}/> Share
          </Button>
        </ButtonGroup>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
            <CommentInput  
            name={'Lewis'}
            comment={props.comment} otherComment={props.otherComment}
            submit={(comment) => props.submitComment(props.id,comment)} />
        </CardContent>
      </Collapse>
    </Card>
  );
}