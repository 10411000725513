import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';
import ResumeIcon from '@material-ui/icons/Assignment'
import RateIcon from '@material-ui/icons/RateReview'
import SendIcon from '@material-ui/icons/Send'
import ApproveIcon from '@material-ui/icons/Check'
import RejectIcon from '@material-ui/icons/ThumbDownAlt'

const useStyles = makeStyles(theme => ({
    icon: {
        margin: '8px'
    }
  }));
  
  export default function SideOptions(props) {
     const classes = useStyles();
  
    return (
      <div style={{position: 'fixed'}}>
          <Grid container justify="center">
          <Grid item xs ></Grid>
                <Grid item xs>
                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} }
                        onClick={() => props.clicked('Resume')}
                        >
                        <ResumeIcon className={classes.icon}/>
                        Resume
                    </Fab>
                    
                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} } 
                        onClick={() => props.clicked('Request')}
                        >
                        <SendIcon className={classes.icon}/>
                        Request
                    </Fab>

                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} } 
                        onClick={() => props.clicked('Approve')}
                        >
                        <ApproveIcon className={classes.icon}/>
                        Approve
                    </Fab>
                    
                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} } 
                        onClick={() => props.clicked('Rate')}
                        >
                        <RateIcon className={classes.icon}/>
                        Rate
                    </Fab>

                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} } 
                        onClick={() => props.clicked('Reject')}
                        >
                        <RejectIcon className={classes.icon} />
                        Reject
                    </Fab>

                </Grid>
                <Grid item xs ></Grid>
                </Grid>    
      </div>
    );
  }