import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TextField,Avatar,ListItem,ListItemAvatar, FormControl,InputLabel, ButtonGroup, Tooltip} from '@material-ui/core/'
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import CameraIcon  from '@material-ui/icons/CameraAlt';
import SendIcon  from '@material-ui/icons/Send';
import VideoIcon  from '@material-ui/icons/Videocam';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';


const useStyles = makeStyles(theme => ({
    textField: {
            width: '100%',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
    

const connection = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
export default function DialogPost(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [post, setPost] = React.useState('')
    const handleChange = (event) => {
        setPost(event.target.value);
    };
    const [tags, setTags] = React.useState([]);

    const handleChangeTag = event => {
        
        // const { options } = event.target;
        // console.log(options)
        // const value = [];
        // for (let i = 0, l = options.length; i < l; i += 1) {
        //     if (options[i].selected) {
        //         value.push(options[i].value);
        //     }
        // }
        setTags(event.target.value);
    };

    return (
        <React.Fragment>     
        <Dialog 
            open={props.open} 
            onClose={props.closed}
            TransitionComponent={Transition}
            keepMounted
            // className={classes.container}
            fullWidth
            maxWidth={'sm'}
            >
            <DialogTitle id="form-dialog-title">New Post</DialogTitle>
            <DialogContent>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />         
                    </ListItemAvatar>
                    <TextField
                        className={classes.textField}
                        placeholder="What are you thinking about ?"
                        value={post}
                        onChange={handleChange}
                        multiline
                        rowsMax="3"
                        />
                </ListItem>
                <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Tag people </InputLabel>
                <Select
                    multiple
                    placeholder={'Tags'}
                    value={tags}
                    onChange={handleChangeTag}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                        {selected.map(value => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    >
                    {connection.map(name => (
                        <MenuItem key={name} value={name} style={getStyles(name, tags, theme)}>
                        {name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions
            
            style={{justifyContent: 'spaceBetween'}}
             >
                <ButtonGroup style={{justifyContent: 'left'}}>
                    <Tooltip title='Upload picture'>
                        <Button 
                            // onClick={() => props.submitPost(post,tags)} 
                            // color="primary"
                            // style={{alignContent: 'left'}}
                            >
                            <CameraIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Upload video'>
                        <Button 
                        // onClick={() => props.submitPost(post,tags)} 
                        // color="primary"
                        // style={{alignContent: 'left'}}
                            >
                            <VideoIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Post'>
                        <Button 
                            onClick={() => props.submitPost(post,tags)} 
                            color="primary">
                            <SendIcon />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                
            </DialogActions>  
        </Dialog>
        </React.Fragment>
        );
}