import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid } from '@material-ui/core';

// const useStyles = makeStyles(theme => ({
//     icon: {
//         margin: '8px'
//     }
//   }));
  
  export default function SideButton(props) {
    // const classes = useStyles();

    return (
      <div style={{position: 'fixed'}}>
          <Grid container justify="center">
          <Grid item xs ></Grid>
                <Grid item xs>
                {props.options.map(option => (
                    <Fab aria-label="checked" variant="extended" 
                        style={{backgroundColor: "lightblue", width: '150px',marginBottom:'10px'} }
                        onClick={() => props.clicked({option})}
                        key={option}
                        >{option}
                    </Fab>
                    ))}

                </Grid>
                <Grid item xs ></Grid>
                </Grid>    
      </div>
    );
  }