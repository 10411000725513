import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ViewVideoIcon from '@material-ui/icons/PlayCircleFilled';
import ViewProfileIcon from '@material-ui/icons/ViewList';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    button1: {
        margin: theme.spacing(-1),
        marginRight: theme.spacing(1),
      },
    button2: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 700,
    },
    fab: {
        margin: theme.spacing(1),
      },
      number: {
          color: red,
          
      }
  }));

export default function DashboardStatus(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {/* <Grid item>
                <ButtonBase className={classes.image}>
                  <img className={classes.img} alt="complex" src="/static/images/grid/complex.jpg" />
                </ButtonBase>
              </Grid> */}
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h5">
                      {props.status.name} just reply with new clip
                    </Typography>
                  </Grid>
                  <Grid item>
                  <Fab aria-label="checked" size="small" variant="extended"
                    style={{backgroundColor: "cyan"}} 
                    className={classes.button1}
                    onClick={props.handleViewClip}
                    >
                    <ViewVideoIcon className={classes.extendedIcon} />
                        View Clip
                    </Fab>
                    <Fab aria-label="checked" size="small" variant="extended" 
                    style={{backgroundColor: "yellow"}} className={classes.button2}
                    onClick={() => props.handleViewCandidate(props.status.name)}
                    >
                    <ViewProfileIcon className={classes.extendedIcon} />
                        View Candidate A
                    </Fab>
                    <Fab aria-label="checked" size="small" variant="extended" 
                    style={{backgroundColor: "lightblue"}} className={classes.button2}>
                    <CheckIcon className={classes.extendedIcon} />
                        Checked
                    </Fab>
                  </Grid>
                </Grid>
                {/* <Grid item>
                    <Fab variant="extended" aria-label="delete" >
                    <Typography variant="body1">
                      3
                    </Typography>
                    </Fab>
                </Grid> */}
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    }