import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import NavBar from './NavBarCandidate';

import DialogPost from '../../component/candidate/DashboardPage/DialogPost'

import JobCard from '../../component/candidate/JobBoard/JobCard'
import Header from '../../component/candidate/JobBoard/Header'
import SideSort from '../../component/candidate/JobBoard/SideSort'


import divClasses from './JobBoardContainer.module.css'
var jobs = [
  {
    id: '2dwdw',
    title: 'Software Developer',
    company: 'Microsoft',
    location: 'California',
    industry : 'Software',
    type: 'Entry-level',
    description: 'A summary of the job',
    saved: false,
    qualification: [
        {
            id: 'dsadad1',
            title: '1 years experience',
            qualified: false
        },
        {
            id: 'dsadad2',
            title: 'Web Design experience',
            qualified: true
        },
        {
            id: 'dsadad3',
            title: 'Agile familiar',
            qualified: true
        }
    ],
    moreDescription: 'This will be a long information for the job',
  },
  {
    id: '2dwdw11',
    title: 'Assistant Accountant',
    company: 'Amazon',
    location: 'Seatle',
    industry : 'Accouting',
    type: 'Junior',
    description: 'A summary of the job',
    saved: false,
    qualification: [
        {
            id: 'dsadad1',
            title: '3 years experience',
            qualified: false
        },
        {
            id: 'dsadad2',
            title: 'Accouting experience',
            qualified: true
        },
        {
            id: 'dsadad3',
            title: 'Good with number',
            qualified: true
        }
    ],
    moreDescription: 'This will be a long information for the job',
  },
  {
    id: '2dwdw323',
    title: 'Senior UI/UX Design',
    company: 'Facebook',
    location: 'New York',
    industry : 'Design',
    type: 'Senior',
    description: 'A summary of the job',
    saved: false,
    qualification: [
        {
            id: 'dsadad1',
            title: '5+ years experience',
            qualified: false
        },
        {
            id: 'dsadad2',
            title: 'Web Design experience',
            qualified: true
        },
        {
            id: 'dsadad3',
            title: 'Adobe expert',
            qualified: true
        }
    ],
    moreDescription: 'This will be a long information for the job',
  },
  {
    id: '2dwdw444',
    title: 'College Recruiter',
    company: 'Google',
    location: 'California',
    industry : 'Human Resource',
    type: 'Entry-level',
    description: 'A summary of the job',
    saved: false,
    qualification: [
        {
            id: 'dsadad1',
            title: '1 years experience',
            qualified: false
        },
        {
            id: 'dsadad2',
            title: 'HR experience',
            qualified: true
        },
        {
            id: 'dsadad3',
            title: 'Social Network and Good with Communicate',
            qualified: true
        }
    ],
    moreDescription: 'This will be a long information for the job',
  },
  {
    id: '2dwdw521521',
    title: 'Movie Director',
    company: 'Netflix',
    location: 'Portland, Oregon',
    industry : 'Film',
    type: 'Senior',
    description: 'A summary of the job',
    saved: false,
    qualification: [
        {
            id: 'dsadad1',
            title: '7 years experience',
            qualified: false
        },
        {
            id: 'dsadad2',
            title: 'Short Documentary Film experience',
            qualified: true
        },
        {
            id: 'dsadad3',
            title: 'Good Director',
            qualified: true
        }
    ],
    moreDescription: 'This will be a long information for the job',
  },

]

// const backgroundShape = require('../images/shape.svg');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 200
      },
    });

class JobBoardContainer extends Component {
    constructor(props){
      super(props)
      this.state = {
          dialog: false,
          search: '',
          location: '',
          industry: '',
          type: {
              senior: false,
              junior: false,
              entry: false,
          },
          result: jobs,

      }
      this.handleMore = this.handleMore.bind(this)
      this.handleApply = this.handleApply.bind(this)
      this.handleSaved = this.handleSaved.bind(this)

      this.handleClose = this.handleClose.bind(this)
      this.handleSubmitApply = this.handleSubmitApply.bind(this)

      this.handleSearch = this.handleSearch.bind(this)
      this.handleJobClick = this.handleJobClick.bind(this)
      this.handleSortLocation = this.handleSortLocation.bind(this)
      this.handleSortIndustry = this.handleSortIndustry.bind(this)
      this.handleSortType = this.handleSortType.bind(this)
      this.handleClearSort = this.handleClearSort.bind(this)
      this.handleClearSearch = this.handleClearSearch.bind(this)
    }

    handleMore = (jobID,option) => {
      console.log('Job ' + jobID + 'will be ' + option)
    }

    handleApply = () => {
        this.setState({dialog:true})
    }
    handleClose () {
        this.setState({dialog:false})
    }
    handleSubmitApply () {
        this.setState({dialog:false})
        console.log('Successful upload ')
        console.log('With Tags ')
    }
    handleSaved (id,option) {
        console.log('Successful ' + option + ' ' + id)
    }
    handleJobClick (option) {
        if(option === 'saved'){
            //load saved job state
            console.log('Load '+option+ ' job')
        }
        else if (option === 'suggested') {
            //load suggested job state
            console.log('Load '+option+ ' job')
        }
        
    }
    handleSearch (search) {
        this.setState({search:search})
        
    }
    handleSortLocation (value) {
        this.setState({location:value})
    }

    handleSortIndustry (value) {
        this.setState({industry:value})
    }

    handleSortType = name => {
        var type = this.state.type
        var newValue = type[name]
        let newType = {...this.state.type, [name]: !newValue};
        this.setState({type:newType});

    }

    handleClearSort () {
        this.setState({location:''})
        this.setState({industry:''})
        var baseType = {
            senior: false,
            junior: false,
            entry: false,
        }
        this.setState({type:baseType})
    }
    handleClearSearch () {
        this.setState({search:''})
    }

    handleSort() {
        const search = this.state.search
        const location = this.state.location
        const industry = this.state.industry
        const type = this.state.type

        var result = jobs

        if(search !== ''){
            result = result.filter(each => 
                each.industry.toLowerCase().includes(search.toLowerCase()) 
                || each.title.toLowerCase().includes(search.toLowerCase()) 
                || each.company.toLowerCase().includes(search.toLowerCase())
                || each.location.toLowerCase().includes(search.toLowerCase())
                || each.type.toLowerCase().includes(search.toLowerCase())
            )
        }

        if(location !== ''){
            result = result.filter(each => each.location.toLowerCase().includes(location.toLowerCase()))
        }
        if(industry !== ''){
            result = result.filter(each => each.industry.toLowerCase().includes(industry.toLowerCase()))
        }
        if(type.senior){
            result = result.filter(each => each.type.toLowerCase().includes('senior'))
        }

        if(type.junior){
            result = result.filter(each => each.type.toLowerCase().includes('junior'))
        }

        if(type.entry){
            result = result.filter(each => each.type.toLowerCase().includes('entry'))
        }

        return result
    }

    render() {
      
    const { classes } = this.props;
    const currentPath = this.props.location.pathname

    var dialogs = null
    // if (this.state.option === 'Clip'){
    if (this.state.dialog){
        dialogs = (
            <React.Fragment>
                <DialogPost
                    open={this.state.dialog}
                    closed={this.handleClose}
                    submitPost={this.handleSubmitPost}
                />
            </React.Fragment>
        );    
    }

    var result = this.handleSort()
    return (
        
        <React.Fragment>
          <CssBaseline />
          <NavBar currentPath={currentPath}/>

          <div className={classes.root}>
            <Header 
            search={this.state.search} resultSearch={result.length} 
            handleSearch={this.handleSearch} handleClick={this.handleJobClick} handleClear={this.handleClearSearch}
            /> 

            <div className={divClasses.wrapper}>
                    <div className={divClasses.left}>
                        <SideSort 
                        locationSort={this.state.location} industrySort={this.state.industry} senior={this.state.type.senior} junior={this.state.type.junior} entry={this.state.type.entry}
                        handleSortLocation={this.handleSortLocation} handleSortIndustry={this.handleSortIndustry} handleSortType={this.handleSortType} handleClear={this.handleClearSort}
                        />
                    </div>
                    <div className={divClasses.right}>
                    {result.map(each =>
                        <JobCard
                        key={each.id} id={each.id}
                        title={each.title} company={each.company} location={each.location} industry={each.industry} type={each.type} saved={each.saved}
                        description={each.description} qualification={each.qualification} moreDescription={each.moreDescription}
                        apply={this.handleApply} moreClicked={this.handleMore} savedClick={this.handleSaved}
                        />
                        )}
                    </div>
            </div>
            
          </div>
          {dialogs}
        </React.Fragment>
      )
    }
}
export default withRouter(withStyles(styles)(JobBoardContainer));