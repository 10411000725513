import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
  }));

export default function FormDialog(props) {
    const classes = useStyles();
    const [newPosition, setValues] = React.useState({
        title: 'Template Title',
        department: '',
        location: '',
        totalNum: '',
        description: '',
    });

    const handleChange = name => event => {
        setValues({ ...newPosition, [name]: event.target.value });
      };

    return (
        <React.Fragment>
        <Dialog open={props.open} 
            onClose={props.closed} 
            
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">New Position</DialogTitle>
            <DialogContent>
            <DialogContentText>
                New Position content
            </DialogContentText>
            <form className={classes.container} noValidate autoComplete="off">
            <TextField
                autoFocus
                required
                margin="normal"
                value={newPosition.title}
                onChange={handleChange('title')}
                // id="name"
                label="Position Title"
                type="string"
                fullWidth
            />
            <TextField
                // autoFocus
                required
                margin="normal"
                // id="name"
                value={newPosition.department}
                onChange={handleChange('department')}
                label="Location"
                type="string"
                fullWidth
            />
            <TextField
                // autoFocus
                required
                margin="normal"
                // id="name"
                value={newPosition.location}
                onChange={handleChange('location')}
                label="Department"
                type="string"
                fullWidth
            />
            <TextField
                // autoFocus
                required
                margin="normal"
                value={newPosition.totalNum}
                onChange={handleChange('totalNum')}
                // id="name"
                label="Total number"
                type="number"
                fullWidth
            />
            <TextField
                // autoFocus
                multiline
                rows='4'
                margin="normal"
                // id="name"
                value={newPosition.description}
                onChange={handleChange('description')}
                label="Position Description"
                type="string"
                fullWidth
            />
            
            {/* <TextareaAutosize style={{width:'555px'}}lWidth  aria-label="minimum height" rows={3} placeholder="Position Description" /> */}
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.closed} color="primary">
                Cancel
            </Button>
            <Button onClick={() => props.created(newPosition)} color="primary">
                Create
            </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>
        );
}