import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {FormControl,InputLabel,Select,MenuItem,Typography,Box,Input } from '@material-ui/core/';

import Rating from '@material-ui/lab/Rating';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const tierList = [
    'Tier 1',
    'Tier 2',
    'Tier 3',
];

const labels = {
    1: 'Terrible',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
  };

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 400
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    rating1: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
      },
    }));

export default function DialogApplication(props) {
    const classes = useStyles();
    const [rate, setRate] = React.useState(3);
    const [dialogForm, setValues] = React.useState({
        category: '',
        position: '',
        tier: '',
        note: '',
        reject: false
    });

    const handleChange = name => event => {
        setValues({ ...dialogForm, [name]: event.target.value });
    };

    let dialog = null
    if (props.type === 'Request') {
        if(props.preCategory !== '') {
            dialog = (
                <form className={classes.container} noValidate autoComplete="off">
                    <FormControl fullWidth >
                        <InputLabel htmlFor="age-simple">Category</InputLabel>
                        <Select
                            value={props.preCategory}
                            onChange={handleChange('category')}
                            input={<Input name="name" id="name-readonly" readOnly />}
                            >
                            {props.category.map(each => (
                            <MenuItem key={each} value={each}>
                                {each}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextField
                        multiline
                        rows='4'
                        margin="normal"
                        value={dialogForm.note}
                        onChange={handleChange('note')}
                        label="Notes"
                        type="string"
                        fullWidth
                    />
            </form>
            )
        }
        else {
            dialog = (
                <form className={classes.container} noValidate autoComplete="off">
                    <FormControl fullWidth >
                        <InputLabel htmlFor="age-simple">Category</InputLabel>
                        <Select
                        autoFocus
                        value={dialogForm.category}
                        onChange={handleChange('category')}
                        >
                        {props.category.map(each => (
                            <MenuItem key={each} value={each}>
                            {each}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextField
                        multiline
                        rows='4'
                        margin="normal"
                        value={dialogForm.note}
                        onChange={handleChange('note')}
                        label="Notes"
                        type="string"
                        fullWidth
                    />
                </form>
            );  
        }
    }

    else if (props.type === 'Approve') {
        dialog = (
            <form className={classes.container} noValidate autoComplete="off">
                <FormControl fullWidth>
                    <InputLabel htmlFor="age-simple">Position</InputLabel>
                    <Select
                    autoFocus
                    value={dialogForm.position}
                    onChange={handleChange('position')}
                    >
                    {props.position.map(each => (
                        <MenuItem key={each} value={each}>
                        {each}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="age-simple">Tier</InputLabel>
                    <Select
                    value={dialogForm.tier}
                    onChange={handleChange('tier')}
                    >
                    {tierList.map(each => (
                        <MenuItem key={each} value={each}>
                        {each}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <TextField
                    multiline
                    rows='4'
                    margin="normal"
                    value={dialogForm.note}
                    onChange={handleChange('note')}
                    label="Notes"
                    type="string"
                    fullWidth
                />
            </form>
        );    
    }

    else if (props.type === 'Rate') {
        dialog = (
            <form className={classes.container} noValidate autoComplete="off">
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend" variant='h6'>Rate {props.name} as </Typography>
                    <div className={classes.rating1}>
                    <Rating
                        name="hover-side"
                        value={rate}
                        onChange={(event, newHover) => {
                            setRate(newHover);
                          }}
                    />
                    <Box ml={2}><Typography component="legend" variant='h6'>{labels[rate]}</Typography></Box>
                    </div>
                </Box>
            </form>
        ); 
    }

    else if (props.type === 'Reject') {
        dialog = (
            <Typography component="legend" variant='h6'>Are you sure to reject {props.name}</Typography>
        ); 
    }

    return (
        <React.Fragment>
        <Dialog open={props.open} 
            onClose={props.closed} 
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.type}</DialogTitle>
            <DialogContent>
                {dialog}
            </DialogContent>
            <DialogActions>
            <Button onClick={props.closed} color="primary">
                Cancel
            </Button>
            <Button onClick={() => props.submitted(dialogForm,rate)} color="primary">
                Submit
            </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>
        );
}