import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Select,MenuItem,FormControl,InputLabel,FormControlLabel,Checkbox,Input,Chip} from '@material-ui/core/'
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const categoryList = ['Elevator Speech','Followership','Projects','Leadership','Teamwork','Greatest Contribution', 'Greatest Failure', 'Passion(s) outside of work']

const tags = ['Data Science','Software','Supply Chain','Accounting','Marketing','Leadership','Personal Interest']

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    chip: {
        margin: 2,
      },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
export default function FormDialog(props) {
    const classes = useStyles();
    // const [newClip, setValues] = React.useState({
    //     title: '',
    //     category: '',
    //     sub1: false,
    //     subCategory: '',
    //     link: '',
    //     tags: [],
    // });
    const [newClip, setValues] = React.useState(props.newClip)
    
    const handleChange = name => event => {
        setValues({ ...newClip, [name]: event.target.value });
    };
    const handleCheckbox = name => event => {
        setValues({ ...newClip, [name]: event.target.checked });
    };

    let sub = null
    if (newClip.sub1) {
        sub = (
            <React.Fragment>
                <TextField
                
                margin="normal"
                value={newClip.subCategory}
                onChange={handleChange('subCategory')}
                // id="name"
                label="Subcategory"
                type="string"
                fullWidth
            />
            </React.Fragment>
        );         
    }


    return (
        <React.Fragment>
        <Dialog open={props.open} 
            onClose={props.closed} 
            
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Upload new Clip</DialogTitle>
            <DialogContent>

            <form className={classes.container} noValidate autoComplete="off">
            
            <TextField
                autoFocus
                required
                margin="normal"
                value={newClip.title}
                onChange={handleChange('title')}
                // id="name"
                label="Title"
                type="string"
                fullWidth
            />

            <FormControl required fullWidth>
                <InputLabel htmlFor="age-simple">Category</InputLabel>
                <Select
                    value={newClip.category}
                    onChange={handleChange('category')}
                    >
                    {categoryList.map(each => (
                    <MenuItem key={each} value={each}>
                        {each}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
            
            <FormControlLabel
                label="Subcategory"
                control={
                <Checkbox checked={newClip.sub1} onChange={handleCheckbox('sub1')}/>
                }
            />

            {/* <TextField
                
                margin="normal"
                value={newClip.subCategory}
                onChange={handleChange('subCategory')}
                // id="name"
                label="Subcategory"
                type="string"
                fullWidth
            /> */}
            {sub}

            <TextField

                required
                margin="normal"
                value={newClip.link}
                onChange={handleChange('link')}
                // id="name"
                label="Link"
                type="string"
                fullWidth
            />

            <FormControl fullWidth>
                <InputLabel htmlFor="age-simple">Tags</InputLabel>
                {/* <Select
                    multiple
                    value={newClip.tags}
                    onChange={handleChangeMultiple}
                    >
                    {categoryList.map(each => (
                    <MenuItem key={each} value={each}>
                        {each}
                    </MenuItem>
                    ))}
                </Select> */}
                <Select
                    multiple
                    value={newClip.tags}
                    onChange={handleChange('tags')}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                        {selected.map(value => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                    >
                    {tags.map(each => (
                        <MenuItem key={each} value={each}>
                        {each}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.closed} color="primary">
                Cancel
            </Button>
            <Button onClick={() => props.created(newClip)} color="primary">
                Create
            </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>
        );
}