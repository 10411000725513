import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Card';

import {Grid, Button, Tooltip,Typography} from '@material-ui/core/';
import {TextField} from '@material-ui/core/';
// import CompanyIcon from '@material-ui/icons/Business';
// import LocationIcon from '@material-ui/icons/Room';
// import IndustryIcon from '@material-ui/icons/BusinessCenter';
// import LevelIcon from '@material-ui/icons/SignalCellularAlt';

// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import SavedIcon from '@material-ui/icons/Bookmark';
import SuggestedIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1,2),
        // marginTop: 5,
        marginBottom: 10,
    },
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
    },
  icon: {
    width: '25px',
    height: '25px',
    margin: '5px',
    },
}));

export default function JobCard(props) {
  const classes = useStyles();
  const [search,setSearch] = React.useState(props.search);

  const handleChange = event => {
    // props.handleChange(event.target.value)
    setSearch(event.target.value)
  };
  const handleSearch = event => {
    if (event.key === "Enter") {
        props.handleSearch(search)
    }
  };

  var searchBox = null
    //   not have searched
  if(props.search === '') {
    searchBox =(
        <React.Fragment>
            <Grid style={{position: 'relative', bottom:'5px'}} container spacing={1} alignItems="flex-end">
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item xs={6}>
                    <TextField InputProps={{disableUnderline: true}} fullWidth value={search} onChange={handleChange} onKeyPress={handleSearch} label="Search for job" />
                </Grid>
            </Grid>
        </React.Fragment>
      )
  }
    // have searched
  else {
    searchBox =(
        <div style={{cursor: 'pointer'}}  onClick={() => props.handleClear()}>
            <SearchIcon style={{position:'relative', top:'5px'}} />
            <Typography display='inline'>
                There are   
            </Typography>
            <Typography display='inline' style={{ margin:'5px',fontWeight:'bold', color: 'lightblue'}}>
                {props.resultSearch} 
            </Typography>
            <Typography display='inline'>
                different jobs based on your search
            </Typography>
            <Typography display='inline' style={{ margin:'5px',fontWeight:'bold', color: 'lightblue'}}>
                {props.search}
            </Typography>
        </div>
      )
  }
  

  return (
    <Paper className={classes.root}>
        <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid item xs={10}>
                {searchBox}
            </Grid>
            <Grid item xs> 
            </Grid>
            <Grid item xs>
                <Grid container>
                    <Grid item xs>
                        <Tooltip title="Saved Job">
                            <Button
                            // style={{color: 'lightblue'}}
                            onClick={() => props.handleClick('saved')}
                            >
                            
                            <SavedIcon/>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title="Suggested Job">
                            <Button
                            // style={{color: 'lightblue'}}
                            onClick={() => props.handleClick('suggested')}
                            >
                            
                            <SuggestedIcon/>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    </Paper>
  );
}