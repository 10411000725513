import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    maxWidth: 600,
    maxHeight: 400,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'lightblue'
  },
});

export default function MediaCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
        <CardActionArea onClick={props.expandClip}>
            <CardMedia
                component="img"
                height="250"
                className={classes.media}
                image={props.img}
                
            //   title="Contemplative Reptile"
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {props.title}
            </Typography>
            <Typography variant="h6" color="textSecondary">
                {props.category}
            </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
            <Button size="small" color="primary">
            Expand Category
            </Button>
            <Button size="small" color="primary" onClick={() => props.requested(props.category)} >
            Request
            </Button>
        </CardActions>
        </Card>
    );
}