export const candidate = [
    {
        name: 'Lewis',
        profileURL: 'google.com'
    },
    {
        name: 'Josh',
        profileURL: 'google.com'
    },
    {
        name: 'Scott',
        profileURL: 'google.com'
    },
    {
        name: 'Issa',
        profileURL: 'google.com'
    },
    {
        name: 'Bruce',
        profileURL: 'google.com'
    },
]

export const positions = [
    {
        id: 0,
        title: 'Software Engineering',
        total: 10,
        current: 7,
        department: 'Technology',
        location: 'Lincoln, Nebraska',
        description: 'This supposed to be a long description of the job',
        disable: false,
        type: 'Internship',
    },
    {
        id: 1,
        title: 'HR Specialist',
        total: 5,
        current: 1,
        department: 'Human Resource',
        location: 'New York City, New York',
        description: 'This supposed to be a long description of the job',
        disable: false,
        type: 'Full-Time',
    },
    {
        id: 2,
        title: 'Accounting',
        total: 7,
        current: 4,
        department: 'Finance',
        location: 'Boston, Massachusetts',
        description: 'This supposed to be a long description of the job',
        disable: false,
        type: 'Part-Time',
    },
    {
        id: 3,
        title: 'Quality Assurance',
        total: 5,
        current: 10,
        department: 'Technology',
        location: 'Seatle, Washington',
        description: 'This supposed to be a long description of the job',
        disable: false,
        type: 'Internship',
    },
    {
        id: 4,
        title: 'Marketing',
        total: 6,
        current: 6,
        department: 'Marketing',
        location: 'Los Angeles, California',
        description: 'This supposed to be a long description of the job',
        disable: false,
        type: 'Full-Time',
    }, 
    {
        id: 5,
        title: 'Backend Developer',
        total: 10,
        current: 1,
        department: 'Technology',
        location: 'Lincoln, Nebraska',
        description: 'This supposed to be a long description of the job',
        disable: true,
        type: 'Senior',
    }, 
]

export const newApplication = [
    {
        name: 'Lewis',
        position: 'Software Engineering',

    },
    {
        name: 'Josh',
        position: 'Marketing',
    },
    {
        name: 'Scott',
        position: 'Project Manager',
    },
    {
        name: 'Issa',
        position: 'Designer',
    },
    {
        name: 'Bruce',
        position: 'Quality Assurance',
    },
]
export const newSuggestion = [
    {
        name: 'Lewis-Suggestion',
        position: 'Software Engineering',
    },
    {
        name: 'Josh-Suggestion',
        position: 'Marketing',
    },
    {
        name: 'Scott-Suggestion',
        position: 'Project Manager',
    },
]
export const newStatus = [
    {
        name: 'Lewis',
        position: 'Software Engineering',
        video: 'Video'
    }
]

export const clipList = [
    {   
        id: 1,
        img: 'https://source.unsplash.com/random',
        title: 'First Team that I lead',
        category: 'Leadership',
    },
    {   
        id: 2,
        img: 'https://source.unsplash.com/random',
        title: 'First Iteration of Ryyze',
        category: 'Project',
    },
    {   
        id: 3,
        img: 'https://source.unsplash.com/random',
        title: 'System Architecture Rework',
        category: 'Contribution',
    },
    {   
        id: 4,
        img: 'https://source.unsplash.com/random',
        title: 'Solving teammate problem',
        category: 'Teamwork',
    },
    {   
        id: 5,
        img: 'https://source.unsplash.com/random',
        title: 'Bad UI/UX',
        category: 'Failure',
    },

]