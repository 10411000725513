import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';

import classes from './ClipBoxContainer.module.css'

import NavBar from './NavBarCandidate';

import LeftButton from '../../component/candidate/ConnectPage/LeftButton'
import ConnectCard from '../../component/candidate/ConnectPage/ConnectCard'
import PendingCard from '../../component/candidate/ConnectPage/PendingCard'
import SuggestionCard from '../../component/candidate/ConnectPage/SuggestionCard'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';

// const backgroundShape = require('../images/shape.svg');
const connectList = [
    {
        name: 'Candidate 1',
        type: 'friend',
        userID: '312313213213',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'College Student at University of Nebraska Lincoln',
    },
    {
        name: 'Candidate 2',
        type: 'friend',
        userID: '3123132132131',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Senior Program Manager at Amazon',
    },
    {
        name: 'Candidate 3',
        type: 'associate',
        userID: '3123132132133',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Lead Marketing Team at Google ',
    },
    {
        name: 'Candidate 4',
        type: 'friend',
        userID: '3123132132135',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'CEO at Facebook',
    },
    {
        name: 'Candidate 5',
        type: 'associate',
        userID: '3123132132136',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'HR Recruiter at Ryyze',
    },
]
const suggestionList = [
    {
        name: 'Suggestion 1',
        userID: '312313213213',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'A at B company',
    },
    {
        name: 'Suggestion 2',
        userID: '3123132132131',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Accountant at UNL',
    },
    {
        name: 'Suggestion 3',
        userID: '3123132132133',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Ph.D at UNL',
    },
    {
        name: 'Suggestion 4',
        userID: '3123132132135',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Software Developer at Netflix',
    },
    {
        name: 'Suggestion 5',
        userID: '3123132132136',
        src: '//www.youtube.com/embed/LWDaDyYbVKM',
        position: 'Lead Design Team at Twitch',
    },
]
const pendingList = [
    {
        name: 'Pending 1',
        type: 'received',
        userID: '312313213213',
        position: 'Program Manager at IBM',
    },
    {
        name: 'Pending 2',
        type: 'sent',
        userID: '3123132132131',
        position: 'UI/UX Design at AirBnb',
    },
    {
        name: 'Pending 3',
        type: 'sent',
        userID: '3123132132133',
        position: 'Back-end Developer at Amazon',
    },
    {
        name: 'Pending 4',
        type: 'sent',
        userID: '3123132132135',
        position: 'Head Coach of NFL team',
    },
    {
        name: 'Pending 5',
        type: 'received',
        userID: '3123132132136',
        position: 'Teacher at UNL',
    },
]



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 200
      },
    });

class ConnectPageContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            dialog : false,
            position: 2,
            name: '',
            userID: '',
            pendingType: '',
            dialogPending: false,
            dialogConnect: false,
            connectType: '',
            message:'',
        }
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleDisconnect = this.handleDisconnect.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleViewProfile = this.handleViewProfile.bind(this)
        this.disconnectUser = this.disconnectUser.bind(this)
        this.handlePending = this.handlePending.bind(this)
        this.finishPending = this.finishPending.bind(this)
        this.handleConnect = this.handleConnect.bind(this)
        this.connectUser = this.connectUser.bind(this)
        this.connectType = this.connectType.bind(this)
        this.handleMessage = this.handleMessage.bind(this)
    }
    handleChangeTab (selection) {
        this.setState({position:selection})
    }
    handleClose () {
        this.setState({dialog:false})

        this.setState({dialogPending:false})
        this.setState({pendingType:''})

        this.setState({dialogConnect:false})
    }
    handleDisconnect (name,userID) {
        this.setState({dialog:true})
        this.setState({name:name})
        this.setState({userID:userID})
    }

    disconnectUser () {
        this.handleClose()
        console.log('successful disconnect user '+ this.state.userID)
    }

    handleSortConnection (list,type) {
        var result = list.filter(each => each.type === type);
        return result
    }

    handleViewProfile () {
        console.log('Successful navigate to public profile')
    }

    handlePending (type,name,userID) {
        this.setState({dialogPending:true})
        this.setState({pendingType:type})
        this.setState({name:name})
        this.setState({userID:userID})
    }

    finishPending () {
        if (this.state.pendingType === 'accept'){
            console.log('successful accept user '+ this.state.userID)
        }
        else if (this.state.pendingType === 'decline'){
            console.log('successful decline user '+ this.state.userID)
        }
        else if (this.state.pendingType === 'remind'){
            console.log('successful remind user '+ this.state.userID)
        }
        this.handleClose()
        
    }

    handleConnect (name,userID) {
        this.setState({dialogConnect:true})
        this.setState({name:name})
        this.setState({userID:userID})
    }

    connectUser () {
        this.handleClose()
        
        console.log('Successful connect user '+ this.state.userID + ' as ' + this.state.connectType)
        console.log('This is the message ' + this.state.message)
    }
    connectType (event) {
        this.setState({connectType:event.target.value})
    }

    handleMessage (event) {
        this.setState({message:event.target.value})
    }

    render() {
    const currentPath = this.props.location.pathname

    var type = null
    if (this.state.position === 1) {
        //Pending List
        type = (
            <PendingCard list={pendingList} action={this.handlePending} viewPublicProfile={this.handleViewProfile} />
        );
    }
    else if (this.state.position === 2) {
        //Connected list
        type = (
            <ConnectCard list={connectList} disconnect={this.handleDisconnect} viewPublicProfile={this.handleViewProfile} />
        );
    }
    else if (this.state.position === 3) {
        //Suggestion List
        type = (
            <SuggestionCard list={suggestionList} connect={this.handleConnect} viewPublicProfile={this.handleViewProfile} />
        );
    }

    var pendingDialog = null
    if (this.state.pendingType === 'accept') {
        //Accept dialog
        pendingDialog = (
            <Dialog
                open={this.state.dialogPending}
                >
                <DialogTitle id="alert-dialog-title">{"Do you want to accept "}{this.state.name}{' invitation'}</DialogTitle>
                <DialogActions>
                <Button onClick={this.handleClose} style={{color:'grey'}}>
                    Cancel
                </Button>
                {/* disconnect in database */}
                <Button onClick={this.finishPending} style={{color:'green'}} autoFocus>
                    Accept
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if (this.state.pendingType === 'decline') {
        //decline dialog
        pendingDialog = (
            <Dialog
                open={this.state.dialogPending}
                >
                <DialogTitle id="alert-dialog-title">{"Do you want to decline "}{this.state.name}{' invitation'}</DialogTitle>
                <DialogActions>
                <Button onClick={this.handleClose} style={{color:'grey'}}>
                    Cancel
                </Button>
                {/* disconnect in database */}
                <Button onClick={this.finishPending} style={{color:'red'}} autoFocus>
                    Decline
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
    else if (this.state.pendingType === 'remind') {
        //remind dialog
        pendingDialog = (
            <Dialog
                open={this.state.dialogPending}
                >
                <DialogTitle id="alert-dialog-title">{"Do you want to remind "}{this.state.name}</DialogTitle>
                <DialogActions>
                <Button onClick={this.handleClose} style={{color:'grey'}}>
                    Cancel
                </Button>
                {/* disconnect in database */}
                <Button onClick={this.finishPending} style={{color:'blue'}} autoFocus>
                    Remind
                </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <React.Fragment>
            <CssBaseline />
            <NavBar currentPath={currentPath}/>
            <br></br>
            <div className={classes.root}>
                <div className={classes.wrapper2}>
                    <div className={classes.side_left}>
                        <LeftButton position={this.state.position} change={this.handleChangeTab} />
                    </div>
                    <div className={classes.middle2}>
                        {type}
                        <br/>
                    </div>
                    
                </div>
            </div>
            {/* pending dialog */}
            {pendingDialog}

            {/* disconnect dialog */}
            <Dialog
                    open={this.state.dialog}
                    // onClose={this.handleClose}
                >
                    <DialogTitle id="alert-dialog-title">{"Do you want to Disconnect with "}{this.state.name}</DialogTitle>
                    <DialogActions>
                    <Button onClick={this.handleClose} style={{color:'grey'}}>
                        Cancel
                    </Button>
                    {/* disconnect in database */}
                    <Button onClick={this.disconnectUser} style={{color:'red'}} autoFocus>
                        Disconnect
                    </Button>
                    </DialogActions>
            </Dialog>

            {/* connect dialog */}
            <Dialog
                    open={this.state.dialogConnect}
                    // onClose={this.handleClose}
                >
                    <DialogTitle id="alert-dialog-title">{"Do you want to connect with "}{this.state.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will send a connection request to {this.state.name}
                        </DialogContentText>
                        <Grid container direction='column'>
                            <Grid item xs>
                                <FormControl style={{margin:'10px',minWidth:'120px'}}>
                                    <InputLabel htmlFor="age-simple">Connect as </InputLabel>
                                    <Select
                                    value={this.state.connectType}
                                    onChange={this.connectType}
                                    inputProps={{
                                        name: 'age',
                                        id: 'age-simple',
                                    }}
                                    >
                                    <MenuItem value={'friend'}>Friend</MenuItem>
                                    <MenuItem value={'associate'}>Associate</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <TextareaAutosize value={this.state.message} style={{margin:'10px',minWidth:'250px'}} rows={2} placeholder="Messages" onChange={this.handleMessage} />;
                            </Grid>
                            
                        </Grid>
                        </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} style={{color:'grey'}}>
                        Cancel
                    </Button>
                    {/* disconnect in database */}
                    <Button onClick={this.connectUser} style={{color:'green'}} autoFocus>
                        Request Connection
                    </Button>
                    </DialogActions>
            </Dialog>

        </React.Fragment>
      )
    }
}
export default withRouter(withStyles(styles)(ConnectPageContainer));