const MenuRecruiter = [
    {
        label: "Dashboard",
        pathname: "/dashboardcandidate"
    },
    {
        label: "Upload",
        pathname: "/uploadpage"
    },
    {
        label: "Clip Box",
        pathname: "/cliplist"
    },
    {
        label: "Job",
        pathname: "/jobboard"
    },
    {
        label: "Profile",
        pathname: "/profilepage"
    },
    {
        label: "Connect",
        pathname: "/connectpage"
    },
    {
        label: "Sign In",
        pathname: "/signin"
    },
    {
        label: "Sign Up",
        pathname: "/signup"
    },
    // {
    //   label: "Signup",
    //   pathname: "/signup"
    // },
    // {
    //   label: "Wizard",
    //   pathname: "/wizard"
    // },
    // {
    //   label: "Cards",
    //   pathname: "/cards"
    // },
    // {
    //   label: "Github",
    //   pathname: "https://github.com/alexanmtz/material-sense",
    //   external: true
    // }
  
  ];
  
  export default MenuRecruiter;