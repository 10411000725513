import React, { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

// const styles = theme => ({
//   link: {
//     textDecoration: 'none',
//     color: 'inherit'
//   },
//   text: {
//     display: 'inline-block',
//     verticalAlign: 'text-bottom'
//   }
// });

class Back extends Component {
  render() {
    // const { classes } = this.props;
    return (
      <React.Fragment>
        <Button size="small" color="primary" onClick={this.props.clicked}>
            <KeyboardArrowLeft />
            Back
        </Button>
      </React.Fragment>
    )
  }
}

// export default withRouter
// // (
//     // withStyles(styles)
//     (Back)
//     // )
//     ;
export default withRouter(Back)