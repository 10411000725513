import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import {Checkbox,FormControlLabel} from '@material-ui/core/'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SortIcon from '@material-ui/icons/Sort';
import ClearIcon from '@material-ui/icons/FormatClear';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  iconButton: {
    padding: 5,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ClipSort(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [category, setCategory] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [sub, setSub] = React.useState(false);
    const [search, setSearch] = React.useState('');

    function handleChangeCategory(event) {
        setCategory(event.target.value);
    }
    function handleChangeTags(event) {
        setTags(event.target.value);
    }
    function handleChangeSub() {
        setSub(!sub);
    }
    function handleChangeSearch(event) {
        setSearch(event.target.value);
    }

    function handleClear() {
        setCategory([]);
        setTags([]);
        setSub(false);
        setSearch('');
    }

    const tagList = props.tags
    const categoryList = props.category

  return (
    // <div className={classes.root}>
    <React.Fragment>
    <Grid container justify="center" spacing={2}>
        <Grid item xs>
            
        </Grid>
        <Grid item container
         justify="center" direction="row"
         spacing={2}
         xs={6}>
             
        <Grid item xs>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="adornment-password">Search</InputLabel>
                <Input
                id="adornment-password"
                value={search}
                onChange={handleChangeSearch}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton className={classes.iconButton} aria-label="search" onClick={() => props.searched(search)}>
                        <SearchIcon />
                    </IconButton>
                    </InputAdornment>
                }
                />
            </FormControl>
        </Grid>

        <Grid item xs>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Category</InputLabel>
                <Select
                multiple
                value={category}
                onChange={handleChangeCategory}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                    <div className={classes.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                    </div>
                )}
                MenuProps={MenuProps}
                >
                {categoryList.map(each => (
                    <MenuItem key={each} value={each} style={getStyles(each, category, theme)}>
                    {each}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Tags</InputLabel>
                <Select
                multiple
                value={tags}
                onChange={handleChangeTags}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                    <div className={classes.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                    </div>
                )}
                MenuProps={MenuProps}
                >
                {tagList.map(each => (
                    <MenuItem key={each} value={each} style={getStyles(each, tags, theme)}>
                    {each}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        
        <Grid item xs>
            <FormControl className={classes.formControl}>
            <FormControlLabel
                label="Subcategory"
                style={{marginTop:"15px"}} 
                control={
                    <Checkbox checked={sub} onChange={handleChangeSub}/>
                }
            />
                
            </FormControl>
        </Grid>


        <Grid item>
            <Fab aria-label="checked" size="small" variant="extended" 
                 style={{backgroundColor: "lightblue", marginTop:"20px"}} 
                onClick={() => props.sorted(category,tags,sub)}
                >
                <SortIcon className={classes.extendedIcon} />
                    Sort
            </Fab>
        </Grid>

        <Grid item>
            <Fab aria-label="checked" size="small" variant="extended" 
                 style={{backgroundColor: "pink", marginTop:"20px"}}
                 onClick={handleClear} 
                >
                <ClearIcon className={classes.extendedIcon} />
                    Clear
            </Fab>
        </Grid>

        </Grid>
        <Grid item xs>
            
        </Grid>

    </Grid>
    </React.Fragment>
    // </div>
  );
}