import React,  { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import NavBar from './NavBarRecruiter';
import { Grid } from '@material-ui/core';

import ApplicationCard from '../../component/recruiter/Application-Card'
import ApplicationSort from '../../component/recruiter/Application/Application-Sort'
import ClipGrid from '../../component/recruiter/Application/ClipGrid'
import SideOptions from '../../component/recruiter/Application/SideOptions'
import DialogButton from '../../component/recruiter/Application/DialogApplication'
import DialogClip from '../../component/DialogClip'
import Back from '../../component/BackButton'

import {newApplication, clipList} from '../../preData'

import classes from './ApplicationListContainer.module.css'


const categories = [
    'Leadership',
    'Failure',
    'Success',
    'Teamwork',
    'Contribution',
    'Project',
];

const positions = [
    'Software Engineering',
    'QA',
    'Marketing',
    'Backend Dev'
];

const options = [
    'Dismiss',
    'Placeholder',
];

class ApplicationListContainer extends Component {
    constructor(props){
        super(props)    
        this.state = {
            type: this.handleRouteType(),
            name: this.handleRouteName(),
            dialog : false,
            option: '',
            preCategory: '',
            preSortPosition: this.handleRoutePosition(),
        }
        this.handleDialog = this.handleDialog.bind(this)
        this.handleSingleRequest = this.handleSingleRequest.bind(this)
        this.handleExpandClip = this.handleExpandClip.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.handleView = this.handleView.bind(this)
        this.handleRouteType = this.handleRouteType.bind(this)
        this.handleRouteName = this.handleRouteName.bind(this)
        this.handleRoutePosition = this.handleRoutePosition.bind(this)
        this.handleBack = this.handleBack.bind(this)
    }
    handleDialog = (type) => {
        this.setState({option:type})       
        this.setState({dialog:true})
    }

    handleSingleRequest (category) {
        this.setState({preCategory:category})   
        this.setState({option:'Request'})       
        this.setState({dialog:true})
    }

    handleExpandClip () {
        this.setState({option:'Clip'})       
        this.setState({dialog:true})
        // console.log("expand pls")
    }

    handleSort (department,location,type,jobs) {
        console.log(department)
        console.log(location)
        console.log(type)
        console.log(jobs)
    }
    handleSubmit (all,rate) {
        if(this.state.option === 'Reject') {
            console.log('Submit Rejected')
        }
        else if (this.state.option === 'Rate'){
            console.log(rate)
        }
        else if (this.state.option === 'Approve'){
            console.log(all.position)
            console.log(all.tier)
            console.log(all.note)
        }
        else if (this.state.option === 'Request'){
            console.log(all.category)
            console.log(all.note)
        }
        
        this.handleClose()
    }
    
    
    handleClose = () => {
        this.setState({option:''}) 
        this.setState({preCategory:''}) 
        this.setState({dialog:false})
    }

    checkOption = () => {
        console.log(this.state.option)
    }

    handleView (name) {
        console.log(name)
        this.setState({name:name})
        this.setState({type:'single'})
    }
    handleRouteType () {
        if(this.props.location.state) {

            return this.props.location.state.type
        }
        return 'multiple'
    }

    handleRouteName () {
        if(this.props.location.state) {

            return this.props.location.state.name
        }
        return 'Default Name'
    }

    handleRoutePosition () {
        if(this.props.location.state) {
            if(this.props.location.state.position){
                return this.props.location.state.position
            }
        }
        return ''
    }

    handleBack () {
        this.setState({name: ''})
        this.setState({type:'multiple'})
    }


    render () {
        const currentPath = this.props.location.pathname

        let currentType = this.state.type
        let currentName = this.state.name

        let preSortDepartment = []
        let preSortLocation = []
        let preSortType = []
        let preSortJob = []

        if (this.state.preSortPosition !== ''){
            preSortJob.push(this.state.preSortPosition)
        }

        let show = null
        if (currentType === 'multiple') {
            show = (
                <React.Fragment>
                <Typography variant="h5" align="center">
                    Application List
                </Typography>
     
                <ApplicationSort 
                sorted={this.handleSort}
                preSortDepartment= {preSortDepartment}
                preSortLocation= {preSortLocation}
                preSortType= {preSortType}
                preSortJob= {preSortJob}
                />

                <Grid container justify="center" spacing={2}>
                    <Grid item xs>
            
                    </Grid>
                    <Grid item xs={8}>
                    {newApplication.map(each => (
                            <ApplicationCard 
                            key={each.name}
                            name={each.name}
                            position={each.position}
                            options={options}
                            clicked={this.handleView}
                            />
                        ))}
                    </Grid>
                    <Grid item xs>
                        
                    </Grid>
                </Grid>

                </React.Fragment>
                
            )
        }
        else if (currentType === 'single'){
            show = (
                <React.Fragment>
                        <Grid   
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between">
                        <Grid item >
                            <Back clicked={this.handleBack}/>
                        </Grid>
                        
                        <Grid item >
                            <Typography style={{position: 'relative', right:'35px'}} variant="h4">
                                {currentName}
                            </Typography>
                        </Grid>

                        <Grid item></Grid>


                    </Grid>
                

                <div className={classes.wrapper}>
                    <div className={classes.middle}>
                        <ClipGrid 
                            title='Public Profile Clip' 
                            expandClip={this.handleExpandClip} 
                            requested={this.handleSingleRequest} 
                            clips={clipList} />
                        <br/>
                        <ClipGrid title='Extra Clip' clips={clipList} />
                    </div>
                    <div className={classes.side}>
                        <SideOptions clicked={this.handleDialog} />
                    </div>
                </div>
         
                </React.Fragment>
                
            )
        }

        let dialogs = null
        if (this.state.dialog) {
            if (this.state.option === 'Clip'){
                dialogs = (
                    <React.Fragment>
                        <DialogClip 
                            open={this.state.dialog}
                            closed={this.handleClose}
                            title="Title"
                            src="//www.youtube.com/embed/LWDaDyYbVKM"
                        />
                    </React.Fragment>
                );    
            }  
            else if (this.state.option === 'Request'){
                dialogs = (
                    <div>
                        <DialogButton
                        type={this.state.option} 
                        open={this.state.dialog}
                        preCategory={this.state.preCategory}
                        category={categories}
                        closed={this.handleClose}
                        submitted={this.handleSubmit}
                        />
                    </div>
                );    
            }  
            else if (this.state.option === 'Approve'){
                dialogs = (
                    <div>
                        <DialogButton
                        type={this.state.option} 
                        open={this.state.dialog}
                        position={positions}
                        closed={this.handleClose}
                        submitted={this.handleSubmit}
                        />
                    </div>
                );    
            }
            else {
                dialogs = (
                    <div>
                        <DialogButton
                        type={this.state.option} 
                        open={this.state.dialog}
                        name={this.state.name}
                        closed={this.handleClose}
                        submitted={this.handleSubmit}
                        />
                    </div>
                );    
            }      
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <NavBar currentPath={currentPath}/>
                
                {show}
                {dialogs}

            </React.Fragment>
        )
    }
}
export default withRouter(ApplicationListContainer);