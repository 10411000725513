import React,  { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import NavBar from './NavBarRecruiter';


import NewApplication from '../../component/recruiter/Dashboard/Dashboard-Application'
import NewStatus from '../../component/recruiter/Dashboard/Dashboard-Status'
import NewSuggestion from '../../component/recruiter/Dashboard/Dashboard-Suggestion'
import DialogClip from '../../component/DialogClip'

import {newApplication,newStatus,newSuggestion} from '../../preData'

class DashboardRecruiter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: false,
            // application,
            // status,
            // suggestion,
        }
        this.handleView = this.handleView.bind(this)
        this.handleViewAll = this.handleViewAll.bind(this)
        this.handleViewPosition = this.handleViewPosition.bind(this)
        this.handleViewClip = this.handleViewClip.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleView (name,position) {
        // console.log(position)
        if(position) {
            this.props.history.push({
                pathname: '/applicationlist',
                state: {
                    type: 'single',
                    name: name,
                    position: position,
                }  
            })
        } 
        else{
            this.props.history.push({
                pathname: '/applicationlist',
                state: {
                    type: 'single',
                    name: name
                }  
            })
        }
    }
    handleViewAll (position) {
        // console.log(position)
        this.props.history.push({
            pathname: '/applicationlist',
            state: {
                type: 'multiple',
                position: position
            }  
        })
    }
    handleViewPosition (title){
        // console.log(title)
        this.props.history.push({
            pathname: '/positionlist',
            state: {
                type: 'single',
                positionTitle: title
            }  
        })
    }

    handleViewClip () {
        this.setState({dialog:true})
    }

    handleClose () {
        this.setState({dialog:false})
    }
    
    render () {
        const currentPath = this.props.location.pathname

        let dialog = null 
        if (this.state.dialog) {
            dialog = (
                <React.Fragment>
                    <DialogClip 
                            open={this.state.dialog}
                            closed={this.handleClose}
                            title="Title"
                            src="//www.youtube.com/embed/LWDaDyYbVKM"
                        />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <NavBar currentPath={currentPath}/>
                <Typography variant="h5" align="center">
                    Dashboard
                </Typography>
                <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        { newApplication.length > 0 ? <NewApplication viewAllClicked={this.handleViewAll} viewPosition={this.handleViewPosition} viewSingle={this.handleView} application={newApplication} /> : null}
                    </Grid>
                    <Grid item xs>
                        { newSuggestion.length > 0 ? <NewSuggestion suggestion={newSuggestion} /> : null}
                    </Grid>
                    <Grid item xs>
                        { newStatus.length > 0 ? <NewStatus handleViewClip={this.handleViewClip} handleViewCandidate={this.handleView} status={newStatus[0]} /> : null}
                    </Grid>
                </Grid>
                {dialog}
            </React.Fragment>
        )
    }
}
export default withRouter(DashboardRecruiter);