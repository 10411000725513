import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import ViewIcon from '@material-ui/icons/Pageview';
import ThumbIcon from '@material-ui/icons/ThumbUp';
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';

import MoreMenu from '../MoreMenu'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        // maxWidth: 700,
        // color: theme.palette.text.secondary,
    },
    card: {
        padding: theme.spacing(1),
    },
    button2: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function ApplicationCard(props) {
    const classes = useStyles();

    function handleClick(option) {
        //option the is one user choose
        // setOpen(true);     
        console.log(option)  
    }


    let options = null
        if (props.options) {
            options = (
                <Grid item xs>
                    {/* <Fab aria-label="checked" size="small" variant="extended" 
                        // style={{backgroundColor: "lightblue"}} 
                        // className={classes.button2}
                        >
                        <ViewIcon className={classes.extendedIcon} />
                            More
                    </Fab> */}
                    <MoreMenu options = {props.options} clicked={handleClick}/>
                </Grid>
            );
            // style.backgroundColor = 'red';
            // style.color = 'blue'           
    }
    
    let position = null
    if (props.position) {
        position = (
            <Typography 
            // style={{margin:'10px'}} 
            variant="h5">
                {props.name} - {props.position}
            </Typography>
        )
    }
    else {
        position = (
            <Typography variant="h5">
                {props.name}
            </Typography>
        )
    }

    return (
    <React.Fragment>
        <Paper className={classes.paper}>
            <Grid container direction="row" >
                    <Grid item xs={6}>
                        {position}
                    </Grid>
                    <Grid 
                    // style={{margin:'10px'}} 
                    item xs> 
                        <ThumbIcon className={classes.extendedIcon}/>
                    </Grid>
                    <Grid 
                    // style={{margin:'10px'}} 
                    item xs={2}> 
                        <Typography variant="body1">
                            4.5/5
                        </Typography>
                    </Grid>
                
            
                <Grid 
                // style={{margin:'5px'}} 
                item xs>
                    <Fab aria-label="checked" size="small" variant="extended" 
                        style={{backgroundColor: "lightgreen"}} 
                        // className={classes.button2}
                        onClick={() => props.clicked(props.name)}
                        >
                        <ViewIcon className={classes.extendedIcon} />
                            View
                    </Fab>
                    
                </Grid>
                {options}
            </Grid>
        </Paper>

    </React.Fragment>
  );
}