import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ViewIcon from '@material-ui/icons/Pageview';
import ViewListIcon from '@material-ui/icons/ViewList';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ApplicationCard from '../Application-Card';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    button1: {
        margin: theme.spacing(-1),
        marginRight: theme.spacing(1),
      },
    button2: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 700,
    },
    fab: {
        margin: theme.spacing(1),
      },
      number: {
          color: red,
          
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
  }));

export default function DashboardApplication(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    function handleExpandClick() {
        setExpanded(!expanded);
    }
    
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {/* <Grid item>
                <ButtonBase className={classes.image}>
                  <img className={classes.img} alt="complex" src="/static/images/grid/complex.jpg" />
                </ButtonBase>
              </Grid> */}
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h5">
                      New Application for Software Engineering
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Fab aria-label="checked" size="small" variant="extended"
                        style={{backgroundColor: "cyan"}} 
                        className={classes.button1}
                        onClick={() => props.viewAllClicked('Software Engineering')} >
                        <ViewListIcon className={classes.extendedIcon} />
                            View All
                    </Fab>

                    <Fab aria-label="checked" size="small" variant="extended" 
                        style={{backgroundColor: "yellow"}} className={classes.button2}
                        onClick={() => props.viewPosition('Software Engineering')}>
                        <ViewIcon className={classes.extendedIcon} />
                            View Position
                    </Fab>

                    <Fab aria-label="checked" size="small" variant="extended" 
                        style={{backgroundColor: "lightblue"}} className={classes.button2}>
                        <CheckIcon className={classes.extendedIcon} />
                            Checked
                    </Fab>

                <Tooltip title="View All">
                    <IconButton
                        className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                        })}
                        size="small"
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon />
                    </IconButton>
                </Tooltip>


            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.application.map(each => (
                    <ApplicationCard 
                        key={each.name}
                        name={each.name} 
                        clicked={(name) => props.viewSingle(name,'Software Engineering')}
                        
                        // clicked={() => this.props.onRemovedPerson(person.id)}
                        />
                ))}
            </Collapse>
                  </Grid>
                </Grid>
                <Grid item>
                    <Fab variant="extended" aria-label="delete" >
                    <Typography variant="body1">
                      {props.application.length}
                    </Typography>
                    </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    }