import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';


// const useStyles = makeStyles(theme => ({
//     container: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         minWidth: 400
//     },
//     textField: {
//         marginLeft: theme.spacing(1),
//         marginRight: theme.spacing(1),
//         width: 200,
//     },
//     dense: {
//         marginTop: 19,
//     },
//     menu: {
//         width: 200,
//     },
//     rating1: {
//         width: 200,
//         display: 'flex',
//         alignItems: 'center',
//       },
//     }));

export default function DialogClip(props) {
    // const classes = useStyles();
    // const [rate, setRate] = React.useState();
    return (
        <React.Fragment>
        <Dialog 
            open={props.open} 
            onClose={props.closed}
            maxWidth="lg"
            >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <iframe 
                    title={props.title} 
                    src={props.src} 
                    height={600} width={900}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                />
            </DialogContent>
            { props.type !== 'public' ? 
                <DialogActions>
                <Button 
                    onClick={props.closed} 
                    color="primary">
                    Cancel
                </Button>
                <Button 
                    // onClick={} 
                    color="primary">
                    Placeholder
                </Button>
                </DialogActions>
                : null
            }
            
        </Dialog>
        </React.Fragment>
        );
}