import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import SendIcon from '@material-ui/icons/Send';
import LikeIcon from '@material-ui/icons/ThumbUp';
import CommentIcon from '@material-ui/icons/Comment';

import {TextField,InputAdornment,IconButton,Tooltip,List,ListItem,ListItemAvatar,Divider, ListItemText, Grid,} from '@material-ui/core/'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    paddingTop: 0,
  },
  textField: {
    width: '100%',
  },
  commentButton: {
    position: 'relative',
    left: '600px',
    bottom: '10px'
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  comment: {
    ...theme.typography.textField,
    padding: theme.spacing(1),
    borderRadius: '10px',
    border: '1px solid #BAE8E8',
  }
}));

export default function CommentInput(props) {
  const classes = useStyles();
  const [comment, setComment] = React.useState(props.comment)
  const handleChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
            <Tooltip title={props.name}>
                <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />         
            </Tooltip>
          
        </ListItemAvatar>
        <TextField
        className={classes.textField}
        placeholder="Comment"
        value={comment}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title="Send">
                    <IconButton onClick={() => props.submit(comment)}>
                        <SendIcon />
                    </IconButton>
                </Tooltip>
                
                </InputAdornment>
            ),
        }}
        />
      </ListItem>
      
        {props.otherComment.length !== 0 ? 
        <React.Fragment>
        <Divider />
        {props.otherComment.map(each =>
        <React.Fragment key={each.id}>
            <ListItem >
            <ListItemAvatar>
                <Tooltip title={each.name}> 
                    <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} />
                </Tooltip>
            </ListItemAvatar>
            <ListItemText>
                <div className={classes.comment}>{each.comment}</div>
            </ListItemText>
            
            
        </ListItem>
        <Grid container alignItems="center" className={classes.commentButton}>
        <Tooltip title='Like'> 
            <IconButton size="small" >
                <LikeIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
        <Tooltip title='Comment'> 
            <IconButton size="small" className={classes.iconButton} >
                <CommentIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
        </Grid>
        
        
        </React.Fragment>
        )} 
        </React.Fragment>
        : null
        }
      

    </List>
        
        
  );
}