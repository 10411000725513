import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SortIcon from '@material-ui/icons/Sort';
import ClearIcon from '@material-ui/icons/FormatClear';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const departments = [
  'Technology',
  'Human Resource',
  'Finance',
  'Marketing',
  'Quality Assurance',
];
const locations = [
    'Lincoln',
    'New York City',
    'Boston',
    'Seatle',
    'Los Angeles',
];
const types = [
    'Internship',
    'Full Time',
    'Part Time',
    'Senior Full Time',
];
const jobs = [
    'Software Engineering',
    'Marketing',
    'Porject Manager',
    'Quality Assurance',
    'Designer'
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PositionSort(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [department, setDepartments] = React.useState(props.preSortDepartment);

    const [location, setLocations] = React.useState(props.preSortLocation);
    const [type, setTypes] = React.useState(props.preSortType);
    const [job, setJobs] = React.useState(props.preSortJob);

    

    function handleChangeDepartments(event) {
        setDepartments(event.target.value);
    }
    function handleChangeLocations(event) {
        setLocations(event.target.value);
    }
    function handleChangeTypes(event) {
        setTypes(event.target.value);
    }
    function handleChangeJobs(event) {
        setJobs(event.target.value);
    }

    function handleClear() {
        setDepartments([]);
        setLocations([]);
        setTypes([]);
        setJobs([]);
    }

  return (
    // <div className={classes.root}>
    <React.Fragment>
    <Grid container justify="center" spacing={2}>
        <Grid item xs>
            
        </Grid>
        <Grid item container
         justify="center" direction="row"
         spacing={3}
         xs={7}>
             <Grid item xs>
        <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-chip">Department</InputLabel>
        <Select
          multiple
          value={department}
          onChange={handleChangeDepartments}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {departments.map(each => (
            <MenuItem key={each} value={each} style={getStyles(each, department, theme)}>
              {each}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-chip">Location</InputLabel>
        <Select
          multiple
          value={location}
          onChange={handleChangeLocations}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {locations.map(each => (
            <MenuItem key={each} value={each} style={getStyles(each, location, theme)}>
              {each}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            </Grid>

        <Grid item xs>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Types</InputLabel>
                <Select
                multiple
                value={type}
                onChange={handleChangeTypes}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                    <div className={classes.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                    </div>
                )}
                MenuProps={MenuProps}
                >
                {types.map(each => (
                    <MenuItem key={each} value={each} style={getStyles(each, type, theme)}>
                    {each}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-chip">Jobs</InputLabel>
                <Select
                multiple
                value={job}
                onChange={handleChangeJobs}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                    <div className={classes.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                    </div>
                )}
                MenuProps={MenuProps}
                >
                {jobs.map(each => (
                    <MenuItem key={each} value={each} style={getStyles(each, job, theme)}>
                    {each}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>

        <Grid item>
            <Fab aria-label="checked" size="small" variant="extended" 
                 style={{backgroundColor: "lightblue", marginTop:"20px"}} 
                onClick={() => props.sorted(department,location,type,job)}
                >
                <SortIcon className={classes.extendedIcon} />
                    Sort
            </Fab>
        </Grid>

        <Grid item>
            <Fab aria-label="checked" size="small" variant="extended" 
                 style={{backgroundColor: "pink", marginTop:"20px"}}
                 onClick={handleClear} 
                >
                <ClearIcon className={classes.extendedIcon} />
                    Clear
            </Fab>
        </Grid>

        </Grid>
        <Grid item xs>
            
        </Grid>

    </Grid>
    </React.Fragment>
    // </div>
  );
}