import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';

import {Grid} from '@material-ui/core/'

import NavBar from './NavBarCandidate';

import Status from '../../component/candidate/DashboardPage/Status'
import NewPost from '../../component/candidate/DashboardPage/NewPost'
import DialogPost from '../../component/candidate/DashboardPage/DialogPost'
var status = [
  {
    id: '2dwdw',
    name: 'Lewis Nguyen',
    position: 'Undergraduate student at University of Nebraska Lincoln',
    statusText: 'A wonderful evening with my team at Sushi 123',
    img : 'https://source.unsplash.com/random',
    love : false,
    comment: '',
    otherComment: [
        {
            id: 'dsadad1',
            name: 'Someone Else',
            comment: 'That is awesome man'
        }
    ],

  },
  {
    id: '2dwdw3',
    name: 'Alex Jefferson',
    position: 'Software Developer at Amazon',
    statusText: 'New journey at Amazon starting today!',
    img : 'https://source.unsplash.com/random',
    love : false,
    comment: '',
    otherComment: [
        {
            id: 'gafgasfg',
            name: 'Someone Else 1',
            comment: 'That is great'
        },
        {
            id: 'sdfaadasd',
            name: 'Someone Else 2',
            comment: 'Looking forward to you man'
        },
        {
            id: 'ggggg',
            name: 'Someone Else 3',
            comment: 'Congrats !!!'
        },
    ],
  },
  {
    id: '2dwdw4',
    name: 'John Murphy',
    position: 'Back-end Developer at IBM',
    statusText: 'Learning React Native at the moment, need help from anyone to solve XYZ',
    // img : 'https://source.unsplash.com/random',
    love : false,
    comment: '',
    otherComment: [

    ],
  },
  {
    id: '2dwdw5',
    name: 'Jennifer Gomez',
    position: 'HR Recruiter at Microsoft',
    statusText: 'Looking for a Senior Software Developer at Google',
    // img : 'https://source.unsplash.com/random',
    love : false,
    comment: '',
    otherComment: [
        {
            id: 'dsadad1',
            name: 'Another HR',
            comment: 'I might know someone, contact me ASAP!'
        }
    ],
  },
]

// const backgroundShape = require('../images/shape.svg');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['100'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 200
      },
    });

class DashboardCandidate extends Component {
    constructor(props){
      super(props)
      this.state = {
          dialog: false,

      }
      this.handleMore = this.handleMore.bind(this)
      this.handleLike = this.handleLike.bind(this)
      this.handleSubmitComment = this.handleSubmitComment.bind(this)
      this.handleNewPost = this.handleNewPost.bind(this)
      this.handleClose = this.handleClose.bind(this)
      this.handleSubmitPost = this.handleSubmitPost.bind(this)
    }

    handleMore = (statusId,option) => {
      console.log('Status ' + statusId + 'will be ' + option)
    }

    handleSubmitComment = (id,text) => {
        console.log('Post ID ' + id + 'get this message ' + text)
    }

    handleLike = (id,type) => {
      if (type === 'love') {
        //change database to like
        console.log('Successful Love the post '+id)
      }
      else if (type === 'unlove') {
        //change database to unlove
        console.log('Successful Un-Love the post '+id)
      }
    }
    

    handleNewPost = () => {
        this.setState({dialog:true})
    }
    handleClose () {
        this.setState({dialog:false})
    }
    handleSubmitPost (post,tags) {
        this.setState({dialog:false})
        console.log('Successful upload ' + post)
        console.log('With Tags ' + tags)
    }

    render() {
      
    const { classes } = this.props;
    const currentPath = this.props.location.pathname

    var dialogs = null
    // if (this.state.option === 'Clip'){
    if (this.state.dialog){
        dialogs = (
            <React.Fragment>
                <DialogPost
                    open={this.state.dialog}
                    closed={this.handleClose}
                    submitPost={this.handleSubmitPost}
                />
            </React.Fragment>
        );    
    }

    return (
        <React.Fragment>
          <CssBaseline />
          <NavBar currentPath={currentPath}/>

          <div className={classes.root}>
            <Typography variant="h4" align="center" gutterBottom> Dashboard Page </Typography>

            <Grid container justify='center'>
              <Grid item xs>

              </Grid>

              <Grid item xs>
                <NewPost 
                newPost={this.handleNewPost}
                />
                {status.map(each =>
                  <Status 
                  key={each.id} id={each.id}
                  name={each.name}  position={each.position} statusText={each.statusText} img={each.img} love={each.love}
                  comment={each.comment} otherComment={each.otherComment} submitComment={this.handleSubmitComment}
                  likeClicked={this.handleLike} moreClicked={this.handleMore}/>
                )}
                {/* <Status statusList={status} likeClicked={this.handleLike} moreClicked={this.handleMore}/> */}
              </Grid>

              <Grid item xs> 
                
              </Grid>
            </Grid>
            
          </div>
          {dialogs}
        </React.Fragment>
      )
    }
}
export default withRouter(withStyles(styles)(DashboardCandidate));