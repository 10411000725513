import React,  { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import NavBar from './NavBarRecruiter';

class SuggestionListContainer extends Component {
    state = {
        

    }


    render () {
        const currentPath = this.props.location.pathname
        return (
            <React.Fragment>
                <CssBaseline />
                <NavBar currentPath={currentPath}/>
                <Typography variant="h6" color="inherit" noWrap>
                    Suggestion List
                </Typography>
            </React.Fragment>
        )
    }
}
export default withRouter(SuggestionListContainer);