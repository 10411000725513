import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Typography,Grid } from '@material-ui/core';

import ClipCard from './ClipCard'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    // width: 1600,
    // height: 00,
  },
}));

export default function ClipGridList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Grid container justify="center">
        <Grid item>
        <Typography gutterBottom variant="h5" align="center">
            {props.title}
        </Typography>
        </Grid>
    </Grid>

        <GridList cellHeight={400} className={classes.gridList} cols={3} spacing={80}>
            {props.clips.map(clip => (
            <GridListTile key={clip.id}>
                {/* <img src={clip.img} alt={clip.title} /> */}
                <ClipCard img={clip.img} title={clip.title} expandClip={props.expandClip} requested={props.requested} category={clip.category} />
            </GridListTile>
            ))}
        </GridList>
    
    </div>
  );
}