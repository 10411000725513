import React from 'react';
import {makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';


import { Paper, Grid, FormGroup, FormControlLabel, Checkbox, OutlinedInput, Tooltip } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/FormatClear';

const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginTop: '10px'
    },
    padding: {
      padding: theme.spacing(1)
    },
    clearButton: {
        position: 'relative',
        right: '10px',
        cursor: 'pointer',  
        '&:hover': {
          color: 'lightblue',
          textDecoration: 'underline',
        }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1),
      },
  }));
const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function SideSort(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('');
    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    const handleChangeLocation = event => {
        props.handleSortLocation(event.target.value)
    }

    const handleChangeIndustry = event => {
        props.handleSortIndustry(event.target.value)
    }




    return (
        <Paper className={classes.paper}>
            {/* header */}
            <Grid container 
            direction="row"
            justify="space-between"
            className={classes.padding}
            spacing={2}
            >
                <Grid item>
                    <Typography style={{fontWeight:'bold', fontSize:'large',position: 'relative',left:'10px'}}>
                        Your job preference
                    </Typography>
                </Grid>
                    
                <Grid item>
                    <Tooltip title="Saved Job">
                        <ClearIcon onClick={() => props.handleClear()} className={classes.clearButton}/>
                    </Tooltip>
                </Grid>
            </Grid>

        {/* Expansion */}
        <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>

            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Location</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <OutlinedInput
                id="component-outlined"
                value={props.locationSort}
                onChange={handleChangeLocation}
                fullWidth
                />
            </ExpansionPanelDetails>

        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>

            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                <Typography>Industry</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <OutlinedInput
                id="component-outlined"
                value={props.industrySort}
                onChange={handleChangeIndustry}
                fullWidth
                />
            </ExpansionPanelDetails>

        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>

            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Job Type</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={props.senior} onChange={() => props.handleSortType('senior')} value="senior" />}
                        label="Senior"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={props.junior} onChange={() => props.handleSortType('junior')} value="junior" />}
                        label="Junior"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox checked={props.entry} onChange={() => props.handleSortType('entry')} value="entry" />
                        }
                        label="Entry-Level"
                    />
                </FormGroup>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>

            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Skills</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
            <OutlinedInput
                id="component-outlined"
                // value={props.industrySort}
                // onChange={handleChangeIndustry}
                fullWidth
                />
                {/* <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={props.senior} onChange={() => props.handleSortType('senior')} value="senior" />}
                        label="Senior"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={props.junior} onChange={() => props.handleSortType('junior')} value="junior" />}
                        label="Junior"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox checked={props.entry} onChange={() => props.handleSortType('entry')} value="entry" />
                        }
                        label="Entry-Level"
                    />
                </FormGroup> */}
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </Paper>
    );
}