import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ViewListIcon from '@material-ui/icons/ViewList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import MoreMenu from '../../MoreMenu'
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
      },
    paper: {
        // padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    card: {
         padding: theme.spacing(1),
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    red: {
        color: 'red',
      },
    blue: {
        color: 'darkblue',
    },
    green: {
        color: 'green',
    },
    close: {
        padding: theme.spacing(0.5),
      },
}));

const options = [
    'Edit Position',
    'Disable Position',
    'Delete Position',
  ];

  const useStyles1 = makeStyles(theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }));
  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  } 

  MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  };

export default function PositionCards(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    function handleExpandClick() {
      setExpanded(!expanded);
    }
    function handleClick(option) {
        //option the is one user choose
        setOpen(true);       
    }
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      }

    let number = null
    if(props.current > props.total) {
        // totalClasses = 'classes.red'
        // console.log('more')
        number = (
            <Typography variant="body1" component="p" className={classes.red} >
            Current / Total Applications : {props.current} / {props.total}
          </Typography>
        )

    }
    if(props.current === props.total) {
        number = (
            <Typography variant="body1" component="p" className={classes.blue} >
            Current / Total Applications : {props.current} / {props.total}
          </Typography>
        )
    }
    if(props.current < props.total) {
        number = (
            <Typography variant="body1" component="p" className={classes.green} >
            Current / Total Applications : {props.current} / {props.total}
          </Typography>
        )
    }



    return (
    <React.Fragment>
    <Grid container spacing={2}>
        <Grid item xs>
            <Paper></Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className={classes.paper}>
            <Card className={classes.card}>
                
            <CardHeader
                action={
                    <MoreMenu options = {options} clicked={handleClick}/>
                }
                title={props.title}
                subheader={props.location}
                />
                
            <CardContent>
                <Typography variant="body1" component="p">
                    Department: {props.department}
                </Typography>
                <Typography variant="body1" component="p">
                    Job Type: {props.type}
                </Typography>
                {number}
            </CardContent>

            <CardActions disableSpacing>

            <Tooltip title="View Positions">
            <IconButton onClick={() => props.clicked(props)} aria-label="view position">
                <ViewListIcon />
            </IconButton>
            </Tooltip>
        
            <Tooltip title="View Description">
                <IconButton
                    className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </IconButton>
            </Tooltip>

            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>Job Description</Typography>
                    <Typography paragraph>
                    {props.description}
                    </Typography>
                </CardContent>
            </Collapse>

        </Card>
            </Paper>
        </Grid>
        <Grid item xs>
            <Paper></Paper>
        </Grid> 
    </Grid>
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="success"
          message="Selection is recorded successfully"
        />
      </Snackbar>

    </React.Fragment>
  );
}