import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import { Avatar,Tooltip,IconButton,TextField } from '@material-ui/core/';


import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    bigAvatar: {
        margin: 10,
        width: 200,
        height: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 450,
      },
}));

export default function CandidateCard(props) {
    const classes = useStyles();

    const [userProfile, setProfile] = React.useState(props.profile)

    const [editMode, setMode] = React.useState(false)

    const handleChange = name => event => {
        setProfile({ ...userProfile, [name]: event.target.value });
    }
    function handleEdit() {
        setMode (!editMode)
    }

    var header = null
    header = (
        <Grid container direction='column'>
            <Grid item xs={4}>
                <Avatar alt="Remy Sharp" src={'https://source.unsplash.com/random'} className={classes.bigAvatar} />
                
            </Grid>
            <Grid item container direction='row'>
                <Grid item>
                    <Grid item xs>
                        { editMode ? 
                            <TextField
                            id="standard-name"
                            label="Name"
                            className={classes.textField}
                            value={userProfile.name}
                            onChange={handleChange('name')}
                            margin="normal"
                            /> 
                            : 
                            <Typography variant="h4" gutterBottom>{userProfile.name}</Typography> 
                        }
                        
                    </Grid>
                    <Grid item xs>
                        { editMode ? 
                            <TextField
                            id="standard-name"
                            label="Title"
                            className={classes.textField}
                            value={userProfile.title}
                            onChange={handleChange('title')}
                            margin="normal"
                            /> 
                            : 
                            <Typography variant="h6">{userProfile.title}</Typography>
                        }
                    </Grid>
                    <Grid item xs>
                        { editMode ? 
                            <TextField
                            id="standard-name"
                            label="Location"
                            className={classes.textField}
                            value={userProfile.location}
                            onChange={handleChange('location')}
                            margin="normal"
                            /> 
                            : 
                            <Typography variant="h6" gutterBottom>{userProfile.location} </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title="Edit">
                        <IconButton style={{position: 'relative',left:'70px'}} onClick={handleEdit} >
                            <EditIcon style={{color:'94ceca'}} />
                        </IconButton>  
                    </Tooltip>
                </Grid>
            </Grid>
            <br/>
            <Grid item container direction='row' xs={8}>
                <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>Rating: {userProfile.rating} </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Premium Candidate">
                        <StarIcon style={{color:'fbe555'}} />
                    </Tooltip> 
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Approved by Ryyze">
                        <CheckIcon style={{color:'94ceca'}} />
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
    <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center" alignContent="center">
                    <Grid item xs>
                        {header}
                    </Grid>
                    <Grid item container justify="flex-end" xs>
                        <iframe 
                            title={'introduction'} 
                            src={'https://www.youtube.com/embed/QgjkjsqAzvo'} 
                            height={300} width={500}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        />
                    </Grid>
                </Grid>              
                <br/>
    </React.Fragment>
  );
}