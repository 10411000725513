import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import {Grid,List, ListItem, ListItemIcon, ListItemText, Button, Tooltip} from '@material-ui/core/';

import CompanyIcon from '@material-ui/icons/Business';
import LocationIcon from '@material-ui/icons/Room';
import IndustryIcon from '@material-ui/icons/BusinessCenter';
import LevelIcon from '@material-ui/icons/SignalCellularAlt';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import SavedIcon from '@material-ui/icons/Bookmark';
import SavedBorderIcon from '@material-ui/icons/BookmarkBorder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import MoreMenu from '../../MoreMenu'

const options = ['Share','Not Interested']

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 800,
    // minWidth: 700,
    // width: '100%',
    width: 800,
    marginBottom: 20,
  },
  cardHeader:{
    paddingTop: 10,
    paddingBottom: 5,
  },
  cardContent:{
    paddingTop: 5,
    paddingBottom: 5,
  },
  list:{
    paddingTop: 5,
    paddingBottom: 5,
    "&:last-child": {
        paddingBottom: 0
    }
  },
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  icon: {
    width: '25px',
    height: '25px',
    margin: '5px',
    },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    '&:hover': {
        backgroundColor: 'white !important',
        color: 'lightblue'
    },
    },

}));

export default function JobCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [saved, setSaved] = React.useState(props.saved);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSaved = () => {
    setSaved(true);
    props.savedClick(props.id,'save')
  };
  const handleUnSaved = () => {
    setSaved(false);
    props.savedClick(props.id,'unsave')
  };
  let subHeader = (
    <React.Fragment>
        <Grid style={{position: 'relative', right:'5px',}} container direction="row" alignItems="center">
        <Grid item >
            <CompanyIcon className={classes.icon}  />
        </Grid>
        <Grid item>
            <Typography variant="body1" color="inherit" style={{ borderRight: '0.1em solid', padding: '0.5em' }}>
                {props.company} 
            </Typography>
        </Grid>
        
        <Grid item>
            <LocationIcon className={classes.icon}  />
        </Grid>
        <Grid item>
            <Typography variant="body1" style={{ borderRight: '0.1em solid', padding: '0.5em' }}>
                {props.location} 
            </Typography>
        </Grid>
        <Grid item>
            <IndustryIcon className={classes.icon}  />
        </Grid>
        <Grid item>
            <Typography variant="body1" style={{ borderRight: '0.1em solid', padding: '0.5em' }} >
                {props.industry}  
            </Typography>
        </Grid>

        <Grid item>
            <LevelIcon className={classes.icon}  />
        </Grid>
        <Grid item>
            <Typography variant="body1" style={{padding: '0.5em' }} >
                {props.type}  
            </Typography>
        </Grid>
        </Grid>
        
    </React.Fragment>
  )

  return (
    <Card className={classes.card}>
        <CardHeader
            className={classes.cardHeader}
            avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
                {props.company.charAt(0)}
            </Avatar>
            }
            action={
                <React.Fragment>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                        {saved 
                        ? 
                            <Tooltip title="UnSave">
                                <Button
                                style={{color: 'lightblue'}}
                                className={classes.button}
                                onClick={handleUnSaved}
                                >
                                
                                <SavedIcon/>
                                </Button>
                            </Tooltip>
                        : 
                            <Tooltip title="Save">
                                <Button
                                // style={{backgroundColor: 'pink'}}
                                className={classes.button}
                                onClick={handleSaved}
                                >
                                <SavedBorderIcon/>
                                </Button>
                            </Tooltip>
                        }
                        </Grid>
                        <Grid item>
                            <MoreMenu options={options} clicked={(option) => props.moreClicked(props.id,option)} />
                        </Grid>
                    </Grid>
                    
                    
                </React.Fragment>
                
            }
            title=
            {<Typography variant="h6" component="p">
                {props.title} 
            </Typography>}
            subheader={subHeader}
            // titleTypographyProps={{align:"right"}}
        />
    
      <CardContent className={classes.cardContent}>
        <Typography variant="body1" component="p">
          Description: {props.description} 
        </Typography>
        <Typography variant="h6"> Qualifications: </Typography>
        <List className={classes.list} >
            {props.qualification.map(each =>
                <React.Fragment key={each.id}>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <CheckCircleIcon style={{color: each.qualified ? '#bfffbf' : '#00FF00'}} />
                        </ListItemIcon>
                        <ListItemText>
                        <Typography variant="body1">
                            {each.title}
                        </Typography>
                        </ListItemText>
                    </ListItem>
                </React.Fragment>
            )
            }
            
            {/* <ListItem>
                <ListItemIcon>
                    <CheckCircleIcon style={{color: '#00FF00'}} />
                </ListItemIcon>
                <ListItemText>
                <Typography variant="body1">
                    Bullet 2
                </Typography>
                </ListItemText>
            </ListItem> */}
        </List>
        
      </CardContent>
      <CardActions disableSpacing>
        <Button onClick={() => props.apply(props.id)}>
            Apply
        </Button>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
            <Typography paragraph>{props.moreDescription}</Typography>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}