import React,  { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import NavBar from './NavBarRecruiter';
import Button from '@material-ui/core/Button'
// import Fab from ''

import PositionCards from '../../component/recruiter/Position/PositionCards'
import PositionSort from '../../component/recruiter/Position/PositionSort'
import PositionTab from '../../component/recruiter/Position/PositionTab'
import Dialog from '../../component/recruiter/Position/PositionForm'

import {positions} from '../../preData'
import { Grid } from '@material-ui/core';

const tier1 = [
    {
        name: 'Lewis-t1',
        note: 'A long note description'
    },
    {
        name: 'Scott-t1',
        note: 'A long note description'
    },
    {
        name: 'Josh-t1',
        note: 'A long note description'
    },
    {
        name: 'Lina-t1',
        note: 'A long note description'
    }
];
const tier2 = [
    {
        name: 'Austin-t2',
        note: 'A long note description'
    },
    {
        name: 'Adren-t2',
        note: 'A long note description'
    },
    {
        name: 'Issa-t2',
        note: 'A long note description'
    },
    {
        name: 'Bruce-t2',
        note: 'A long note description'
    },
];
const tier3 = [
    {
        name: 'Lisa-t3',
        note: 'A long note description'
    },
    {
        name: 'Tom-t3',
        note: 'A long note description'
    },
    {
        name: 'Jess-t3',
        note: 'A long note description'
    },
    {
        name: 'Jimmy-t3',
        note: 'A long note description'
    },
];
const interested = [
    {
        name: 'Interested 1',
        note: 'A long note description'
    },
    {
        name: 'Interested 2',
        note: 'A long note description'
    },
    {
        name: 'Interested 3',
        note: 'A long note description'
    },
    {
        name: 'Interested 4',
        note: 'A long note description'
    },
];


class PositionListContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            dialog : false,
            type: 'multiple',
            position: null,
            tabValue: 0,

        }
        this.handleDialog = this.handleDialog.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.handleViewPosition = this.handleViewPosition.bind(this)
        this.handleViewCandidate = this.handleViewCandidate.bind(this)
    }

    handleSort (department,location,type) {
        console.log(department)
        console.log(location)
        console.log(type)
    }
    handleCreate (newPosition) {
        console.log(newPosition)
        this.handleDialog(false)
    }
    
    handleDialog = (state) => {
        this.setState({dialog:state})
    }

    handleViewPosition (position) {
        console.log(position)
        this.setState({position:position})
        this.setState({type:'single'})
    }

    handleViewCandidate (name) {
        console.log(name)
        this.props.history.push({
            pathname: '/applicationlist',
            state: {
                type: 'single',
                name: name
            }  
        })
    }

    handleTabChange (newValue) {
        this.setState({tabValue:newValue})
    }

    render () {
        const currentPath = this.props.location.pathname
        let main = null


        let currentType = this.state.type
        let currentPosition = this.state.position

        if (this.props.location.state) {
            currentType = this.props.location.state.type
            let title = this.props.location.state.positionTitle
            let sort = positions.filter( function (each) {
                return each.title === title
            })
            currentPosition = sort[0]
            // console.log(currentPosition)
            
            
        }

        if (currentType === 'multiple') {
            main = (
                <React.Fragment>
                    <Typography variant="h5" align="center" gutterBottom>
                    Position List
                </Typography>
                <Grid container justify='center'>
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => this.handleDialog(true)}>
                        Create New Position
                    </Button>
                    </Grid>
                </Grid>

                <PositionSort 
                sorted={this.handleSort}
                />

                {positions.map(position => (
                    <PositionCards 
                        key={position.id}
                        title={position.title} 
                        location={position.location} 
                        department={position.department} 
                        current={position.current} 
                        total={position.total}
                        description={position.description}
                        type={position.type}
                        disable={position.disable}      
                        clicked={this.handleViewPosition}
                        />
                ))}

                </React.Fragment>
                
            )
        }
        else if (currentType === 'single') {
            main = (
                <React.Fragment>
                    <Typography variant="h5" align="center">
                        {currentPosition.title}
                    </Typography>

                    <Grid container justify='center'>
                        <Grid item xs></Grid>

                        <Grid item container xs={6}>
                            <Grid item>
                                <Typography variant="body1" component="p">
                                    Department: {currentPosition.department}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Job Type: {currentPosition.type}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    Current / Total: {currentPosition.current} /  {currentPosition.total}
                                </Typography>
                            </Grid>
                            <Grid item>
                                
                            </Grid>
                            

                            <PositionTab 
                            tier1={tier1} 
                            tier2={tier2}
                            tier3={tier3}
                            interested={interested}
                            clickView={this.handleViewCandidate}
                            /> 

                        </Grid>

                        <Grid item xs></Grid>
                    </Grid>
                </React.Fragment>
                   
            )       
        }

        let dialogs = null
        if (this.state.dialog) {
            dialogs = (
                <React.Fragment>
                    <Dialog 
                    open={this.state.dialog}
                    closed={() => this.handleDialog(false)}
                    created={this.handleCreate}
                            />
                </React.Fragment>
            );         
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <NavBar currentPath={currentPath}/>
                {main}
                {dialogs}

            </React.Fragment>
        )
    }
}
export default withRouter(PositionListContainer);