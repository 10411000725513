const MenuRecruiter = [
    {
        label: "Dashboard",
        pathname: "/dashboardrecruiter"
    },
    {
        label: "Position",
        pathname: "/positionlist"
    },
    {
        label: "Application",
        pathname: "/applicationlist"
    },
    {
        label: "Suggestion",
        pathname: "/suggestionlist"
    },
    {
        label: "Sign In",
        pathname: "/signin"
    },
    {
        label: "Sign Up",
        pathname: "/signup"
    },
    // {
    //   label: "Signup",
    //   pathname: "/signup"
    // },
    // {
    //   label: "Wizard",
    //   pathname: "/wizard"
    // },
    // {
    //   label: "Cards",
    //   pathname: "/cards"
    // },
    // {
    //   label: "Github",
    //   pathname: "https://github.com/alexanmtz/material-sense",
    //   external: true
    // }
  
  ];
  
  export default MenuRecruiter;